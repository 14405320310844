import React from 'react';

const Button = (props) => {
  let className = props.className || "";
  className += props.block ? " btn-block" : "";
  className += props.size ? ` btn-${props.size}` : "";
  className += props.active ? " active" : "";

  // if both color and outline are provided, the color is applied to the outline; if only the color is provided, the color is for the button
  className += props.outline && props.color
    ? ` btn-outline-${props.color}`
    : props.outline
      ? " btn-outline-secondary"
      : props.color
        ? ` btn-${props.color}`
        : "";

  return (
    <button
      className={`btn-reset ${className}`}
      disabled={props.disabled}
      id={props.id}
      type={props.type}
      href={props.href}
      target={props.target}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}
export default Button;