import axios from "axios";
import { lazy, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import mixpanel from 'mixpanel-browser';
import store from "store";
import { 
  createCareRecipient,
  updateCareRecipient 
} from "actions";

import { videoFileTypes } from "./constants";

export const devServer = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Mapping of required and optional fields for profile completion metrics
// The first value is the field on the model - user for all users, stored on user model
// and caregiver for caregiver only fields, stored on the care recipient model
export const REQUIRED_AND_OPTIONAL_FIELDS = {
  required: {
    user: [["handle", null], ["age_bracket", null], ["is_care_giver", null]],
    caregiver: [["country", null]],
    caregiverUSA: [["city", null], ["state", null]]
  },
  optional: {
    user: [
      ["communication_email", null],
      ["profile_image", "https://carecopilot-avatars.s3.amazonaws.com/personal-avatar.png"], 
      ["first_name", null], 
      ["last_name", null]
    ],
    caregiver: [["relationship", null], ["affinities", null], ["concerns", null]]
  }
}

export const urlToTitle = (url) =>
  url
    .split("-")
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(" ");

export const titleToUrl = (title) => title.toLowerCase().split(" ").join("-");

export const isInNYC = field => {
  if (!field) return false;
  const inNYC = ["manhattan", "brooklyn", "the bronx", "bronx", "queens", "staten island"]
  return inNYC.includes(field.toLowerCase());
}

export const isUSA = field => {
  if (!field) return false;
  const USAVariations = ["united states of america", "united states", "usa", "us", "u.s.a.", "u.s.", "america"];
  return USAVariations.includes(field.toLowerCase());
}

export const iconMap = {
  "Black": {
    "alt": "Black Power Fist",
    "style": { maxWidth: "7%" },
    "img": "black_power_fist.jpg",
  },
  "LGBTQIA+": {
    "alt": "Rainbow Flag",
    "style": { maxWidth: "4%" },
    "img": "rainbow_flag.jpg",
  },
  'Latino/a/x': {
    "alt": "Hola Speech Bubble",
    "style": { maxWidth: "5%" },
    "img": "hola.jpg",
  },
  'Korean': {
    "alt": "Korean Flag",
    "style": { maxWidth: "5%", borderRadius: "50%" },
    "img": "korean-flag.jpg",
  },
  'Russian': {
    "alt": "Russian Flag",
    "style": { maxWidth: "4%", border: "1px solid black", borderRadius: "50%" },
    "img": "russian-flag.jpg",
  }
};

export const renderAffinities = (affinities) => {
  return affinities.map((affinity, i) => {
    return <img
      alt={iconMap[affinity].alt}
      key={affinity}
      style={iconMap[affinity].style}
      src={require(`assets/icons/${iconMap[affinity].img}`).default}
    />
  })
}

// Handle radio form fields with an "Other" write-in option
export const onSubmitRadioHelper = (field, submitData) => {
  // submitData.relationship/concerns is the radio submission, *_other is the text input for "Other"
  const fieldOther = field + "_other";

  if (submitData[field] === "other") {
    if (submitData[fieldOther]) {
      // if relationship/concerns === "other", update relationship/concerns to the value of *_other
      submitData[field] = submitData[fieldOther];
    } else {
      // if other is selected but has no value, prevent form submission
      return { error: fieldOther };
    }
  }
  // if a user types in the "Other" box, then selects a default relationship/concern, other still has a value and should be deleted
  if (submitData[field]) delete submitData[fieldOther];
  return submitData;
}

export const emailValidationPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = () => {
  return (
    { required: true,
      pattern: {
        value: emailValidationPattern,
        message: "Please provide a full email address, complete with domain (.com, .co, .org)"
      }
    }
  )
}

export const humanReadableDate = (comparisonDate) => {
  let convertedDate = new Date(comparisonDate);
  let today = new Date();

  const daysBetween = (date1, date2) => {

    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  }

  let dayDiff = daysBetween(today, convertedDate);

  // return undefined if the comparison date is in the future or isn't parseable
  if (isNaN(dayDiff) || dayDiff < 0) return;

  if (dayDiff === 0) {
    return "today";
  } else if (dayDiff === 1) {
    return "yesterday";
  } else if (dayDiff > 1 && dayDiff < 31) {
    return `${dayDiff} days ago`;
  } else if (dayDiff >= 31 && dayDiff < 62) {
    return "1 month ago";
  } else if (dayDiff >= 62 && dayDiff < 365) {
    let monthsAgo = Math.round(dayDiff / 31);
    return `${monthsAgo} months ago`;
  } else if (dayDiff >= 365 && dayDiff < 730) {
    return "1 year ago";
  } else {
    let yearsAgo = Math.round(dayDiff / 365);
    return `${yearsAgo} years ago`;
  }   
}

export const handleTextAreaInput = textarea => {
  setTimeout(() => {
    textarea.style.height = "";
    if (textarea.scrollHeight > 200) {
      textarea.style.height = "200px";
      textarea.style.overflowY = "auto";
    } else {
      textarea.style.height = textarea.scrollHeight + "px";
      textarea.style.overflowY = "hidden";
    }
  }, 0);
}

export const truncate = text => {
  if (text?.length > 15) return text.substring(0, 15);

  return text;
}

// get tag names from the object that holds their name and ID
export const getTagNames = tags => {
  const tagNames = [];
  tags.forEach(tag => {
    if (tag) tagNames.push(tag.tag_name);
  });
  return tagNames;
}

// get tag IDs from their name
export const getTagIDs = (tags, tagNames) => {
  const tagIDs = [];
  tagNames.forEach(name => {
    tagIDs.push(tags.find(cat => cat.tag_name === name).id);
  });
  return tagIDs;
}

// get full category objects from their IDs
export const getTags = (tags, postTags) => {
  return postTags.map(cat => tags.find(c => c.id === cat));
}

export const convertFromBoolean = value => {
  if (value === true) return "Yes";
  if (value === false) return "No";
  return value;
}

export const convertToBoolean = value => {
  if (value === "Yes" || value === "No") {
    return value === "Yes";
  }
  return value;
}

export const getTruncatedFileName = (file, maxLength) => {
  if (file.length < maxLength) {
    return file;
  }
  // if file name is longer than maxLength, keep the first 9 characters and the last 4, plus the file type. Replace the middle characters with "..."
  const fileName = file.split(".")[0];
  const fileType = file.split(".")[1];
  return `${fileName.slice(0, 9)}...${fileName.slice(-4)}.${fileType}`;
}

export const getTruncatedString = (text, maxLength) => {
  return text.length <= maxLength
    ? text
    : text.slice(0, maxLength) + "...";
}

export const convertDate = dateString => {
  // Converts a datetime.now value from Django (2023-04-07T18:00:05.776595Z) to month/day/year format
  let dateArray = dateString.substring(0,10).split("-");

  return parseInt(dateArray[1]) + "/" + parseInt(dateArray[2]) + "/" + dateArray[0]
}

// Recursively traverse nodes to extract their text contents
export const getTextFromNode = node => {
  if (typeof node === "string" || typeof node === "number") {
    return node;
  } else if (Array.isArray(node)) {
    return node.map(getTextFromNode).join("");
  } else if (typeof node === "object" && node.props?.children) {
    return getTextFromNode(node.props.children);
  }
  return "";
}

export const isVideo = url => {
  if (!url) return false;
  url = url.toLowerCase();
  const fileType = url.slice(url.lastIndexOf(".") + 1);
  return videoFileTypes.includes(fileType);
}

export const getLocationDisplay = (user) => {
  if (!user || !user.city) return null;
  
  // Return city and country if outside USA or city and state if in USA
  if (user.country && !isUSA(user.country)) {
    return `${user.city}, ${user.country}`;
  }
  if (user.city && user.state) {
    return `${user.city}, ${user.state}`;
  }
  return null;
}

// Mixpanel utility functions
export const getCurrentPageMixpanel = () => {
  let currentPage = window.location.pathname.slice(1) || "home";
  if (currentPage === "post-login" || currentPage === "post-logout") {
    return null;
  }
  if (currentPage.startsWith("community/")) {
    return {
      post: currentPage.slice(10),
      source: "individual forum post"
    }
  } else if (currentPage.startsWith("blog/")) {
    return {
      post: currentPage.slice(5).replaceAll("-", " "),
      source: "blog post"
    }
  // For individual script or video pages
  } else if (currentPage.indexOf("/") !== -1) {
    return {
      id: currentPage.split("/")[1],
      source: currentPage.split("/")[0]
    }
  }
  return { source: currentPage };
}

export const trackClickMixpanel = (action, additionalData) => {
  if (window.location.host !== "carecopilot.co") return;
  const currentPage = getCurrentPageMixpanel();
  if (currentPage) {
    mixpanel.track("Click", { ...currentPage, action: action, ...additionalData });
  }
}

export const trackPageMixpanel = () => {
  if (window.location.host !== "carecopilot.co") return;
  if (getCurrentPageMixpanel()) {
    mixpanel.track('Page View', getCurrentPageMixpanel());
  }
}

// Redux utility functions
export const createOrUpdateCareRecipient = (updatedFields, token, recipient) => {
  if (recipient.id) {
    store.dispatch(updateCareRecipient(recipient, updatedFields, token));
  } else {
    store.dispatch(createCareRecipient(updatedFields, token));
  }
}

export const getOrCreateCareRelationship = (relationship, callback) => {
  devServer.get("api/care-relationship/").then(res => {
    if (res?.status === 200) {
      const defaultRelationships = res?.data;
      const relationshipData = defaultRelationships.find(r => r.relationship === relationship);

      if (relationshipData) {
        callback(relationshipData);
      } else {
        devServer.post("/api/care-relationship/", {relationship})
        .then(resp => {
          callback(resp.data);
        })
        .catch(err => console.log(err));
      }
    } else {
      console.log("HTTP Response status:", res?.status);
    }
  }).catch(err => {
    console.log(err)
  });
};

export const getSurveyData = (topic, successCallback, errorCallback=console.log) => {
  devServer.get(`api/survey-questions/${topic}/`).then(res => {
    if (res?.status === 200) {
      // Callback might be something like setSurveyData to set a state variable
      successCallback(res?.data);
    } else {
      console.log("HTTP Response status:", res?.status);
    }
  }).catch(err => {
    errorCallback(err);
  });
}

export const renderLoader = () => <></>

// Function to avoid lazy loading errors that can be corrected with refresh
export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(
        'page-has-been-force-refreshed'
      ) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(
        'page-has-been-force-refreshed',
        'false'
      );

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Try to force refresh the page before throwing an error
        window.localStorage.setItem(
          'page-has-been-force-refreshed',
          'true'
        );
        return window.location.reload();
      }

      // The page has already been reloaded once, so throw the error
      throw error;
    }
  });

export const hideSurvey = (currentUser, topic) => {
  return currentUser.survey_answers.find(a => a.question === topic && (a.answer || a.seen_count >= 3) )
}

// Custom hooks
export const useToken = (testing) => {
  const { getAccessTokenSilently, isLoading, user } = useAuth0();
  const [token, setToken] = useState();

  useEffect(() => {
    if (!isLoading && user) {
      getAccessTokenSilently()
        .then(token => {
          if (testing) console.log(token);
          setToken(token);
        })
        .catch(err => console.log(err));
    }
  }, [getAccessTokenSilently, isLoading, testing, user]); 

  return token;
}
