import blogposts from "../blogposts/blogposts.json";
const renderLink = (text, url) => <a href={url} target="_blank" rel="noreferrer">{text}</a>;

export const videoFileTypes = ["3gp", "avi", "flv", "f4v", "m2v", "m4p", "m4v", "mkv", "mov", "mp2", "mp4", "mpeg", "mpg", "mpv", "nsv", "ogg", "qt", "quicktime", "svi", "webm", "wmv"];

export const STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "Washington, D.C.",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const getAccountInputSections = (currentUser) => {
  return (
    [
      {
        field: "name",
        inputs: [
          {name: "first_name", required: false, title: "First name"},
          {name: "last_name", required: false, title: "Last name"}
        ],
        title: "Name",
        value: `${currentUser?.first_name || ""} ${currentUser?.last_name || ""}`.trim() || "Not provided"
      },
      {
        field: "communication_email",
        inputs: [{name: "communication_email", required: true, defaultValue: `${currentUser?.communication_email || currentUser?.email}`}],
        subtitle: "Emails will go to this address",
        title: "Communication email address*",
        value: currentUser?.communication_email || currentUser?.email || "Not provided"
      },
      {
        field: "handle",
        inputs: [{name: "handle", required: true}],
        title: "Username*",
        value: currentUser?.handle || "Not provided"
      }
    ]
  )
}

export const getAccountDropdownSections = (ageRanges) => {
  return (
    [
      {
        field: "age_bracket",
        data: ageRanges,
        title: "Your age range*"
      },
      {
        field: "is_care_giver",
        data: ["Yes", "No"],
        title: "Are you actively taking care of someone right now?*"
      }
    ]
  )
}

export const servicesNavigationCategories = [
  {
    title: `Caregiving\nAdvisors`,
    image: "concierge-services"
  },
  {
    title: `In-Home\nCare Agencies`,
    image: "home-care-services"
  },
  {
    title: "Caregiving\nAttorneys",
    image: "legal-services"
  },
  {
    title: "More\nto Come!",
    image: "more-services"
  },
  // {
  //   title: "Care Copilots",
  //   image: "home-care-services"
  // },
  // {
  //   title: "Caring Callers",
  //   image: "concierge-services"
  // },
  // {
  //   title: "Local Services",
  //   image: "local-services"
  // }
];

export const callingServicesText = {
  "Care Copilots": {
    title: "Former Caregivers. Current Advisors.",
    subtitle: (
      <>
        Taking care of an aging loved one is hard. Speaking with someone who's done it before -and can provide actionable guidance -makes it easier. Speak with one of our knowledgeable former caregivers for just $50/month. Cancel any time.
        <ul>
          <li>Penatibus nibh purus morbi tempus ve sollicitudin eget sit amet.</li>
          <li>Pellentesque lacus nam diam pretium orci  turpis diam. Etiam at sollicitudin eget sit.</li>
          <li>Fermentum pharetra penatibus donec ut vitae in mi ac morbi consectetur </li>
        </ul>
      </>
    )
  },
  "Caring Callers": {
    title: "Heading copy for Caring Callers",
    subtitle: (
      <>
        Suspendisse morbi volutpat placerat et. Fermentum pharetra penatibus donec ut vitae in mi ac morbi. Consectetur bibendum egestas massa ultrices facilisis enim pulvinar.
        <ul>
          <li>Penatibus nibh purus morbi tempus ve sollicitudin eget sit amet.</li>
          <li>Pellentesque lacus nam diam pretium orci  turpis diam. Etiam at sollicitudin eget sit.</li>
          <li>Fermentum pharetra penatibus donec ut vitae in mi ac morbi consectetur </li>
        </ul>
      </>
    )
  }
}

export const callingServices = {
  "Care Copilots": [
    {
      name: "Alyse Dunn",
      role: "Former Family Caregiver",
      description: "Lorem ipsum dolor sit amet consectetur. Ut pellentesque pretium morbi augue adipiscing at nunc. Penatibus nibh purus morbi tempus vel. Pellentesque lacus nam diam pretium orci  turpis diam. Etiam at sollicitudin eget sit amet. Suspendisse morbi volutpat placerat et. Fermentum pharetra penatibus donec ut vitae in mi ac morbi. Consectetur bibendum egestas massa ultrices facilisis enim pulvinar.",
      img: "https://carecopilot-static-assets.s3.amazonaws.com/founder-headshot.png"
    },
    {
      name: "Jane Doe",
      role: "Social Worker, Former Family Caregiver",
      description: "Felis sed sed consectetur pretium odio ut. Donec nulla tellus velit fames vitae turpis sagittis scelerisque. Senectus gravida amet commodo blandit elit volutpat ante. Aliquam imperdiet commodo ullamcorper ipsum metus. At id orci libero ut lorem quis. Hac libero praesent dui amet morbi nibh ornare. A dictum vitae enim urna nisi facilisi. Tempor in sed vivamus et. At dui fusce eleifend in ut tincidunt mauris in phasellus.",
      img: "https://carecopilot-static-assets.s3.amazonaws.com/test-headshot-1.png"
    },
    {
      name: "Samantha Smith",
      role: "Social Worker, Former Family Caregiver",
      description: "Felis sed sed consectetur pretium odio ut. Donec nulla tellus velit fames vitae turpis sagittis scelerisque. Senectus gravida amet commodo blandit elit volutpat ante. Aliquam imperdiet commodo ullamcorper ipsum metus. At id orci libero ut lorem quis. Hac libero praesent dui amet morbi nibh ornare. A dictum vitae enim urna nisi facilisi. Tempor in sed vivamus et. At dui fusce eleifend in ut tincidunt mauris in phasellus.",
      img: "https://carecopilot-static-assets.s3.amazonaws.com/test-headshot-2.png"
    }
  ],
  "Caring Callers": [
    {
      name: "Michael Scott",
      role: "Former Family Caregiver",
      description: "Lorem ipsum dolor sit amet consectetur. Ut pellentesque pretium morbi augue adipiscing at nunc. Penatibus nibh purus morbi tempus vel. Pellentesque lacus nam diam pretium orci  turpis diam. Etiam at sollicitudin eget sit amet. Suspendisse morbi volutpat placerat et. Fermentum pharetra penatibus donec ut vitae in mi ac morbi. Consectetur bibendum egestas massa ultrices facilisis enim pulvinar.",
      img: "https://carecopilot-static-assets.s3.amazonaws.com/test-headshot-1.png"
    },
    {
      name: "Sam Smith",
      role: "Social Worker, Former Family Caregiver",
      description: "Felis sed sed consectetur pretium odio ut. Donec nulla tellus velit fames vitae turpis sagittis scelerisque. Senectus gravida amet commodo blandit elit volutpat ante. Aliquam imperdiet commodo ullamcorper ipsum metus. At id orci libero ut lorem quis. Hac libero praesent dui amet morbi nibh ornare. A dictum vitae enim urna nisi facilisi. Tempor in sed vivamus et. At dui fusce eleifend in ut tincidunt mauris in phasellus.",
      img: "https://carecopilot-static-assets.s3.amazonaws.com/test-headshot-2.png"
    }
  ]
};

export const serviceTagMapping = {
  "In-Home Care Agencies": "In-Home Care",
   "Caregiving Attorneys": "Attorney"
}

export const serviceCategoryText = {
  "Caregiving Advisors": {
    buttonText: "Find an Advisor",
    category: "caregiving_advisor",
    serviceType: "Caregiving Advisor services",
    subtitle: "Taking care of an aging loved one is hard. Speaking with someone who's done it before -and can provide actionable guidance -makes it easier. Speak with one of our knowledgeable former caregivers for just $50/month. Cancel any time.",
    title: "Former Caregivers. Current Advisors."
  },
  "In-Home Care Agencies": {
    buttonText: "Find caregivers",
    category: "in_home_care",
    serviceType: "In-Home Care Agency services",
    subtitle: "An in-home care agency is a company that employs dozens of reliable and background-checked in-home care aides. We've spent hundreds of hours scouring the very best agencies based on pricing, reviews, and more. These are our top picks.",
    title: "See a Short List of the Top 3 In-Home Care Agencies Near You"
  },
  "Caregiving Attorneys": {
    buttonText: "Find legal services",
    category: "attorney",
    serviceType: "Caregiving Attorney services",
    subtitle: "If you want to manage your loved one's finances or make medical decisions for them when they are unable to, you'll need to become their power of attorney. Attorneys who specialize in this kind of work are often called 'family attorneys', 'eldercare attorneys', or 'caregiving attorneys'.",
    title: "Let's find attorneys in your loved one's area."
  }
}

export const partnerServiceDetailedCategoryMapping = {
  "In-Home Care Agencies": [
    {
      languages: "Languages spoken"
    },
    {
      boroughs: "Boroughs served"
    },
    {
      minimumHours: "Daily minimum",
      // tooltip: "Lorem ipsum dolor sit amet consectetur. Neque pellentesque augue pellentesque et. Etiam quis vehicula varius molestie. Learn more on our FAQ page."
    },
    {
      rate: "Hourly rate range",
      // tooltip: "Lorem ipsum dolor sit amet consectetur. Neque pellentesque augue pellentesque et. Etiam quis vehicula varius molestie. Learn more on our FAQ page."
    },
    {
      payment: "Payment options",
      // tooltip: "Lorem ipsum dolor sit amet consectetur. Neque pellentesque augue pellentesque et. Etiam quis vehicula varius molestie. Learn more on our FAQ page."
    }
  ],
  "Caregiving Attorneys": [
    {
      languages: "Languages spoken"
    },
    {
      licencedIn: "Licensed states"
    },
    {
      practicing: "Practice areas"
    },
    {
      rate: "Pricing"
    },
    {
      locations: "Office locations"
    }
  ]
}

export const partnerServices = {
  "In-Home Care Agencies": [
    {
      name: "Alvita Care",
      description: "Alvita Care has been providing high quality in-home care to families in New York and New Jersey for nearly 20 years. They have a variety of seasoned in-home care aides who can even the most skilled nursing care - like wound care - from the comfort of the patient's own home.",
      email: "info@alvitacare.com",
      logo: "https://carecopilot-static-assets.s3.amazonaws.com/alvita-logo.png",
      phone: "(800) 414-7611",
      recommended: "You may only need in-home care for a short-period of time, like after a loved one discharges from the hospital. Some in-home care agencies only support long-term patients.",
      url: "alvitacare.com",
      reviews: {
        yelp: 4.5,
        google: 4.5
      },
      moreDetails: {
        languages: "English",
        minimumHours: "6 hours (can occasionally accommodate fewer hours)",
        payment: "Private pay",
        boroughs: "Manhattan, The Bronx, Brooklyn, Queens, Staten Island",
        rate: "$25-$35"
      },
      category: "In-Home Care Agencies"
    },
    // {
    //   name: "New Century Home Care Aides For the Homebound",
    //   description: "New Century Home Care has been serving New Yorkers for over 20 years. In addition to in-home care, they also offer a unique remote monitoring system that monitors patients when they're alone.",
    //   email: "info@newcenturyhc.com",
    //   logo: "https://carecopilot-static-assets.s3.amazonaws.com/new-century-logo.png",
    //   phone: "718-998-2100",
    //   recommended: "You're taking care of someone with limited financial resources. New Century Home Care has staff that can help secure Medicaid for your loved one, and CDPAP assistance for you. ",
    //   url: "newcenturyhc.com",
    //   reviews: {
    //     google: 4
    //   },
    //   moreDetails: {
    //     languages: "English, Spanish",
    //     minimumHours: "4 hours (can occasionally accommodate fewer hours)",
    //     payment: "Private pay, Medicaid",
    //     boroughs: "Manhattan, The Bronx, Brooklyn, Queens",
    //     rate: "$25-$35"
    //   },
    //  category: "In-Home Care Agencies"
    // },
    {
      name: "Renewal Memory Partners",
      description: "Renewal Memory Partners is a concierge homecare agency specializing in care for people living with neurological challenges, such as Alzheimer's, Lewy body dementia, frontotemporal dementia, Parkinson's disease and more.",
      email: "info@renewalmemory.org",
      logo: "https://carecopilot-static-assets.s3.amazonaws.com/renewal-logo.png",
      phone: "(212) 498-9600",
      recommended: "You're seeking an agency with specialized knowledge in neurocognitive disorders and/or; intellectual engagement is an important aspect of your loved one's needs. Renewal employs college-educated care providers, many with backgrounds in the arts.",
      url: "renewalmemory.org",
      reviews: {
        yelp: 5,
        google: 4.6
      },
      moreDetails: {
        languages: "English",
        minimumHours: "4 hours",
        payment: "Private pay",
        boroughs: "Manhattan, The Bronx, Brooklyn, Queens, Westchester",
        rate: "$40-$50",
      },
      category: "In-Home Care Agencies"
    }
  ],
  "Caregiving Attorneys": [
    {
      name: "Tully Law Group, Elder Care & Estate Planning",
      description: "Tully Law Group has helped families in New York City and Long Island with various legal needs for over 20 years. In addition to their experienced attorneys, they have an in-house eldercare coordinator who helps families with non-legal needs like finding social services that they may qualify for.",
      email: "brian@tullyelderlaw.com",
      logo: "https://carecopilot-static-assets.s3.amazonaws.com/tully-law-logo.png",
      phone: "(631) 424-2800",
      recommended: "You're taking care of an older adult and you see the benefit of working with a single firm that can assist you with legal and various non-legal needs.",
      url: "tullyelderlaw.com",
      reviews: {
        "lawyers.com": 5
      },
      moreDetails: {
        languages: "English",
        licencedIn: "New York",
        practicing: [
          "Durable Power of Attorney",
          "Medicaid application",
          "Wills and Trusts",
          <>
            <a href="https://tullyelderlaw.com/services/ "target="_blank" rel="noreferrer">
              Additional practice areas
            </a>
          </>,
        ],
        rate: [
          "Basic Estate Plan (Will, Durable Modified Power of Attorney, Health Care Proxy, and Living Will): $3,000",
          "Revocable Trust Estate Plan (Basic Estate Plan + Revocable Trust + Client Care Program): $5,000",
          "Elder Care Protection Plan (Basic Estate Plan + Irrevocable Asset Protection Medicaid Trust + Client Care Program): $7,000"
        ],
        locations: {"Long Island:": ["700 Old Country Road, Suite 2\nRiverhead, NY 11901", "532 Broadhollow Road, Suite 123\nMelville, NY 11747"]}
      },
      category: "Caregiving Attorneys"
    },
    {
      name: "Cona Elder Law",
      description: "Cona Elder Law is an award-winning law firm with over 25 years of experience. Despite the name, Cona Elder Law does more than just elder law. They also assist with legal matters related to individuals with special needs, and veterans.",
      email: "elder@conalaw.com",
      logo: "https://carecopilot-static-assets.s3.amazonaws.com/cona-elder-law-logo.png",
      phone: "(631) 619-2533",
      recommended: "You need a Spanish-speaking attorney, and/or you care about supporting a female-owned law firm.",
      url: "conaelderlaw.com",
      reviews: {
        "lawyers.com": 5
      },
      moreDetails: {
        languages: "English, Spanish",
        licencedIn: "New York",
        practicing: [
          "Durable Power of Attorney",
          "Medicaid application",
          "Wills and Trusts",
          <>
            <a href="https://conaelderlaw.com/practice/ "target="_blank" rel="noreferrer">
              Additional practice areas
            </a>
          </>,
        ],
        rate: [
          "Durable Power of Attorney: $250",
          "Will: $1,000 - $2,500",
          "Trust: $5,000 - $7,000",
        ],
        locations: {"Long Island:": ["225 Broadhollow Road, Suite 200\nMelville, New York 11747", "106 Crystal Brook Hollow Road, Port Jefferson Station, NY 117760"], "\nManhattan:": ["570 Lexington Avenue, 21st Floor\nNew York, New York 10022"]}
      },
      category: "Caregiving Attorneys"
    }
  ]
};

export const bookableServices = {
  "Caregiving Advisors": [
    {
      city: "New York",
      state: "New York",
      description: "Unlimited calls with one of our expert care concierges for a one month period. You can rely on your care concierge to assist you with any medical, financial, or legal challenges that you're navigating. Or, you can call them for a listening ear during a difficult time.",
      images: ["https://carecopilot-static-assets.s3.amazonaws.com/services-expert-1.png", "https://carecopilot-static-assets.s3.amazonaws.com/services-expert-2.png"],
      payment: "https://buy.stripe.com/dR64ilcZodiN8bScMP",
      price: "$50 / month",
      title: "Caregiving Advisor - New York"
    }
  ],
  "In-Home Care Agencies": [],
  "Caregiving Attorneys": []
}

export const servicesFaqQuestions = {
  "Caregiving Advisors": [
    {
      question: "What is a Caregiving Advisor and what can they help me with?",
      answer: "A caregiving advisor is a professional who helps you offload the research and phone calls that can bog you down when you're helping out an aging loved one. If you need to find a nursing home near your loved one, your advisor might provide you with a short list of homes that are within your budget and have available beds. If you're not sure what you need, an advisor can help you identify where to start."
    },
    {
      question: "What is the professional background of a Caregiving Advisor?",
      answer: (
        <>
          All advisors:
          <ul>
            <li>Are based in the U.S.</li>
            <li>Speak English as a first language</li>
            <li>Have taken care of an adult loved one, like an ill spouse or an aging parent, for at least 3 years</li>
          </ul>
        </>
      )
    }
  ],
  "In-Home Care Agencies": [
    {
      question: "What is an in-home care agency?",
      answer: "An in-home care agency is a company that employs reliable and background-checked in-home care aides. We've spent hundreds of hours scouring the very best agencies based on pricing, reviews, and more, and provided you with our top picks."
    },
    {
      question: "How does CareCopilot vet these in-home care agencies?",
      answer: (
        <>
          We use a multi-step process to identify and vet all in-home care agencies that we share including:
          <ol>
            <li>An initial online search using tools like Yelp, Google, and {renderLink("Medicare's in-home care rating tool", "https://www.medicare.gov/")} to identify the very best in-home care agencies in the area.</li>
            <li>Two to three phone calls with the in-home care agency to learn more about their pricing, practices and more.</li>
            <li>Two to three phone calls with clients, clinicians, or other unbiased third parties to help validate the legitimacy and reputation of that in-home care agency.</li>
          </ol>
        </>
      )
    },
    {
      question: "How much does in-home care cost?",
      answer: (
        <>
          The cost of in-home care varies from state to state. In most states, the hourly cost of in-home care is between $20/hr and $40/hr. Typically, the hourly rate increase with:
          <ol>
            <li>The level of care that the patient needs. Aides that have to perform more skilled care, like wound care, cost more than aides that help with unskilled care, like dressing and feeding.</li>
            <li>The amount of experience that the aide has. An aide with 10 years of experience will typically command a higher hourly rate than an aide with 2 years of experience.</li>
            <li>The cost of living in the city where the services are being rendered. An aide in a city with a high cost of living will likely command a higher rate than an aide in a city with a lower cost of living.</li>
          </ol>
        </>
      ),

      other: "The cost of in-home care varies from state to state. In most states, the hourly cost of in-home care is between $20 and $40 per hour. Typically, the hourly rate increase with the level of care that the patient needs. For example, someone who only needs help with light cooking and cleaning may be able to get an in-home care aide for $20, but someone who needs help with medications, wound care, and more may find it difficult to find an in-home care aide for less than $40 per hour. Another factor that can contribute to the cost of in-home care is the professional background of the in-home care aide. Aides with significant experience or college degrees tend to command a higher hourly rate."
    },
    {
      question: "What types of questions should I ask when evaluating in-home care agencies?",
      answer: (
        <>
          As you evaluate in-home care agencies, we suggest that you ask about:
          <ol>
            <li>Their cancellation policy. I.e.: their policy if you cancel, or if the aide must cancel due to an emergency.</li>
            <li>Whether or not their aides are willing to physically lift patients (assuming that your loved one needs to be lifted).</li>
            <li>Their training and background check process for in-home care aides.</li>
            <li>The process that the agency uses to pair aides with families.</li>
          </ol>
        </>
      )
    },
    {
      question: "Will my loved one be able to get the same in-home care aide every time they need help?",
      answer: (
        <>
          All of the agencies that we share understand the importance of ensuring as much consistency as possible for patients. That said, it can be challenging to ensure that your loved one will definitely see the same aide during every visit because:
          <ol>
            <li>
              Aides get sick too, and if an aide needs to take a sick day, the agency will present you with a replacement that you can accept or deny.</li>
            <li>
              If your loved one needs a lot of care, i.e. 50+ hours per week, a single aide might not be sufficient to provide all of the hours of care needed.
            </li>
          </ol>
        </>
      )
    },
    {
      question: "Why do these agencies have a minimum number of hours? I only want someone for 2 hours or less each day.",
      answer: (
        <>
          Agencies have minimums because:
          <ol>
            <li>In-home care aides often want a minimum number of hours with patients in order to make the trip financially viable for them. Aides only get paid for the time that they spend with patients. They don't get paid for travel time. So, if an aide must travel 30 min to get to a patient, it's usually not worth the trip for them if they can only see that patient for 2 hours.</li>
            <li>In some states, in-home care aides are unionized, and the union demands that aides receive shifts of no fewer than 4 hours.</li>
          </ol>
        </>
      )
    }
  ],
  "Caregiving Attorneys": [
    {
      question: "Why might I need an attorney, and what can they help me with?",
      answer: (
        <>
          If a loved one is on the brink of no longer being able to manage their own finances and you want to do it for them, you'll need to get a '{renderLink("Durable Financial Power of Attorney", "https://www.nia.nih.gov/health/getting-your-affairs-order-checklist-documents-prepare-future")}'. If a loved one is no longer able to make their own medical decisions and you're planning on making decisions on their behalf, you'll need a '{renderLink("Durable Medical Power of Attorney", "https://www.nia.nih.gov/health/getting-your-affairs-order-checklist-documents-prepare-future")}'. If a loved one is already at the point where they can no longer make their own medical or legal decisions, then you might need a '{renderLink("conservatorship", "https://www.jacksonwhitelaw.com/guardianships-conservatorships/conservatorship-vs-durable-power-of-attorney")}'. An attorney can help you with these legal documents and more.
        </>
      )
    },
    {
      question: (
        <>
          Can I use {renderLink("Legal Zoom", "https://www.legalzoom.com/")}, {renderLink("Rocket Lawyer", "https://www.rocketlawyer.com/")}, or another online service for legal documents that I know I need?
        </>
      ),
      answer: (
        <>
          Maybe! Speaking with a licensed attorney will better ensure that you have everything that you might need, but we understand that that's simply out of budget for some families. An alternative is to do your own research to identify what you need, and then use online tools like {renderLink("Legal Zoom", "https://www.legalzoom.com/")} or {renderLink("Rocket Lawyer", "https://www.rocketlawyer.com/")} to draw up specific documents that you need.
        </>
      )
    }
  ]
}

export const slideNotifications = {
  featureVoting: {
    title: "Thank you for sharing your thoughts!",
    text: "Your feedback will help the development and improvement of CareCopilot.",
    mobileText: "Thank you for sharing!"
  }
}

export const resourcesFaqQuestions = [
  {
    question: "Mauris hac leo elementum aliquet faucibus quam tellus?",
    answer: "Lorem ipsum dolor sit amet consectetur. Eget et rhoncus sed mi at diam arcu. Dui est mauris at ullamcorper maecenas pretium in scelerisque. Urna et faucibus id nisi ac commodo in. Dignissim ultrices convallis egestas neque ut enim magna et metus. Tortor lectus auctor enim a egestas. Donec habitasse pellentesque justo in sit eget cras. Pretium ac interdum dui arcu iaculis ornare cursus tincidunt. Sed donec nibh et mi sagittis pellentesque aliquam ac. Nisi at consectetur facilisis dolor habitasse. Laoreet commodo tortor leo sed blandit massa aliquam. Ut fermentum varius id mi lorem. Libero integer lorem venenatis convallis tincidunt amet a. Ullamcorper senectus egestas nisl euismod ipsum ultrices cursus."
  },
  {
    question: "Turpis ut vitae aenean tempus nec condimentum donec bibendum?",
    answer: "Lorem ipsum dolor sit amet consectetur. Eget et rhoncus sed mi at diam arcu. Dui est mauris at ullamcorper maecenas pretium in scelerisque. Urna et faucibus id nisi ac commodo in. Dignissim ultrices convallis egestas neque ut enim magna et metus. Tortor lectus auctor enim a egestas. Donec habitasse pellentesque justo in sit eget cras. Pretium ac interdum dui arcu iaculis ornare cursus tincidunt. Sed donec nibh et mi sagittis pellentesque aliquam ac. Nisi at consectetur facilisis dolor habitasse. Laoreet commodo tortor leo sed blandit massa aliquam. Ut fermentum varius id mi lorem."
  },
  {
    question: "Lorem ipsum dolor sit amet consectetur ames vitae metus habitant fermentum?",
    answer: "Lorem ipsum dolor sit amet consectetur. Eget et rhoncus sed mi at diam arcu."
  },
  {
    question: "Donec bibendum verra sed lobortis non lorem et quam metus mattis?",
    answer: "Lorem ipsum dolor sit amet consectetur. Eget et rhoncus sed mi at diam arcu. Dui est mauris at ullamcorper maecenas pretium in scelerisque. Urna et faucibus id nisi ac commodo in. Dignissim ultrices convallis egestas neque ut enim magna et metus. Tortor lectus auctor enim a egestas. Donec habitasse pellentesque justo in sit eget cras. Pretium ac interdum dui arcu iaculis ornare cursus tincidunt. Sed donec nibh et mi sagittis pellentesque aliquam ac."
  },
  {
    question: "Erat egestas non viverra ut suspendisse egestas sapien tellus turpis dedelim caroin?",
    answer: "Lorem ipsum dolor sit amet consectetur. Eget et rhoncus sed mi at diam arcu. Dui est mauris at ullamcorper maecenas pretium in scelerisque. Urna et faucibus id nisi ac commodo in."
  }
];

export const conversationScripts = [
  {
    id: 1,
    author: "Alyse Dunn",
    image: "https://carecopilot-static-assets.s3.amazonaws.com/script-1.png",
    title: "Convincing your loved one to accept help",
    readTime: "5 min read",
    content: "",
    description: "It can be hard to convince a loved one to agree to an in-home care aide, a senior community, or any other form of help. These sample scripts can help you get to a 'yes'.",
    icon: "https://carecopilot-static-assets.s3.amazonaws.com/founder-headshot.png",
    url: "convincing-your-loved-one-to-accept-help",
    scenarios: [
      {
        title: "Scenario #1: A loved one who insists they don't need help",
        script: "“I've been feeling stressed out and overwhelmed lately. I spend a lot of time worrying about your health and safety. The only thing that would help me sleep better at night is if you would accept an in-home care aide to come in and help around the house for a few days a week. It would help me a lot.”",
        audience: "A loved one who clearly needs help, but insists that they don't. This script works best for loved ones who have demonstrated a willingness to make personal sacrifices for you.",
        tips: "Communicate that the help isn't really for them. It's for YOU."
      },
      {
        title: "Scenario #2: A loved one who wants help, but won't take action",
        script: "“Thank you so much for agreeing to the senior community. It makes me really happy. I know this was a hard decision for you, so I want to do what I can to make these next steps easy. I made an appointment to tour Sunrise Senior Living next Tuesday. Do you want to get ice cream after our appointment?”",
        audience: "A loved one who has agreed to receive help, but they continuously fail to take any next steps. For example, they don't tour the senior community they agreed to move into, they don't give you the financial information that you requested, e.t.c.",
        tips: ["Acknowledge them for the commitment that they already made", "Frame your action as 'doing your part'", "Pose the next step as a given instead of an option", "End the conversation by presenting them with a real option, to provide them with a sense of agency and control."]
      },
      {
        title: "Scenario #3: A loved one who won't talk about their care",
        script: "In this case, you may have to have several short conversations about their care that start light, and get more meaningful over time. References to others might also be helpful here. For example, in the first conversation, you may say something like \"My friend's aunt recently passed away. She said that she wanted to be cremated. I don't think I'd want that. Would you?\" In a subsequent conversation, you may ask something like \"I'm making an end of life plan so that everyone knows what I want when that time comes. Will you help me?\" The goal isn't to get all information at once. The goal is to slowly normalize talking about care and end of life plans, and then have more meaningful conversations over time.",
        audience: "A loved one who refuses to engage in any conversations about their care.",
        tips: "Be careful not to push the conversation too much and attempt to get a lot of information at once. It could sabotage future attempts at conversation."
      }
    ]
  },
  {
    id: 2,
    image: "https://carecopilot-static-assets.s3.amazonaws.com/script-2.png",
    title: "Taking the car keys away",
    readTime: "5 min read",
    content: "",
    description: "If your loved one can no longer drive safely and it's time to take the keys away, these scripts can help ease the tension of that difficult conversation.",
    url: "taking-the-car-keys-away",
  },
  {
    id: 3,
    image: "https://carecopilot-static-assets.s3.amazonaws.com/script-3.png",
    title: "Asking a family member to help more",
    readTime: "5 min read",
    content: "",
    description: "If you're the primary caregiver for your loved one and you want more help from friends or family, these scripts will help you get their buy-in and their support.",
    url: "asking-a-family-member-to-help-more",
  }
];

export const instructionalVideos = [
  {
    id: 1,
    image: "https://carecopilot-static-assets.s3.amazonaws.com/video_still_1.png",
    title: "3 Tips for Addressing Anger and Agitation in Loved Ones with Dementia",
    readTime: "2 min",
    content: "",
    description: "Loved ones who have been diagnosed with dementia may suffer from anger, and/or agitation. In this video, occupational therapist Emilia Bourland walks you through three tips that will help you prevent these behaviors in your loved one, and address them if they arise.",
    embedID: "ok23BTLwgLE?si=WJb4zGmkqCriZJFo",
    uploadedBy: <><b>Occupational therapist, Emilia Bourland</b> of <a href="https://www.higherstandardscaregivertraining.com/">Higher Standards Caregiver Training</a></>,
    icon: "https://carecopilot-static-assets.s3.amazonaws.com/logo-a.png",
    url: "3-tips-for-addressing-anger-and-agitation"
  },
  {
    id: 2,
    author: "Brenda Freed, MA, MUS",
    image: "https://carecopilot-static-assets.s3.amazonaws.com/video_still_2.png",
    title: "Alzheimer's Symptoms by Stage: Mild, Moderate, and Severe",
    readTime: "3 min",
    content: "",
    description: "In this video, Brenda Freed, MA, MUS, walks you through typical symptoms that are seen in Alzheimer’s patients during the mild, moderate, and severe stages of the disease. For more from Brenda, visit MackenzieMeetsAlzheimers.com",
    embedID: "9xBj9AN0lbE?si=yaLwY8D2jUPJ-r8L",
    uploadedBy: <><b>Brenda Freed, MA, MUS</b> of <a href="https://www.mackenziemeetsalzheimers.com/">MacKenzie Meets Alzheimer's</a></>,
    icon: "https://carecopilot-static-assets.s3.amazonaws.com/logo-m.png",
    url: "alzheimers-symptoms-by-stage"
  },
  {
    id: 3,
    image: "https://carecopilot-static-assets.s3.amazonaws.com/video_still_3.png",
    title: "Reaching Alzheimer's Patients through Music and Emotion",
    readTime: "3 min",
    content: "",
    description: "The part of the brain that regulates music and emotions is often the last part of the brain to be impacted by Alzheimer's. This means that Alzheimer's patients often respond favorably to positive music and emotions, even in the late stages of the disease. In this video, Alder Allensworth, MM, RN, CIHC and co-founder of MackenzieMeetsAlzheimers.com, shares more.",
    embedID: "GA2VvshpyEQ?si=nrWjhuqAo0Mm3v6V",
    uploadedBy: <><b>Alder Allensworth, MM, RN, CIHC</b> of <a href="https://www.mackenziemeetsalzheimers.com/">MacKenzie Meets Alzheimer's</a></>,
    icon: "https://carecopilot-static-assets.s3.amazonaws.com/logo-m.png",
    url: "reaching-alzheimers-patients-through-music-and-emotion"
  }
];

export const authorBios = {
  "Alyse Dunn": {
    tagline: "Certified CareCopilot",
    img: "https://carecopilot-static-assets.s3.amazonaws.com/founder-headshot.png",
    description: "Alyse has 3 years of experience solving the practical, day-to-day challenges that family caregivers face, like finding a reliable in-home care aide. Alyse is also a trained hospice volunteer."
  }
}

export const homeCards = [
  { ...blogposts[0],
    action: "Continue reading",
    author: "Alyse Dunn",
    description: "1 out of every 7 Americans take care of an adult loved one, like an ailing spouse or an aging parent. If you're not taking care of someone today, you soon might, particularly as 'Baby Boomers' continue to age into needing care. For life milestones like the birth of child or the purchase of a home, you can find a wide variety of books, blog, apps, and more that provide you with a step-by-step guide of what to expect. Unfortunately, for the milestone of taking care of an adult loved one, such guidance is few and far between.",
    icon: "https://carecopilot-static-assets.s3.amazonaws.com/founder-headshot.png",
    link: "blog",
    tag: { icon: "fa-solid fa-book", text: "article" }
  },
  { ...instructionalVideos[1],
    action: "Watch video",
    link: "videos",
    tag: { icon: "fa-regular fa-circle-play", text: "video" }
  },
  { ...conversationScripts[0],
    action: "Continue reading",
    link: "scripts",
    tag: { icon: "fa-regular fa-comments", text: "script" }
  }
];

export const homeCopilots = [
  {
    image: "https://carecopilot-static-assets.s3.amazonaws.com/founder-headshot.png",
    name: "Alyse Dunn",
    tagline: "B.B.A.\nFounder and CEO"
  },
  {
    image: "https://carecopilot-static-assets.s3.amazonaws.com/nancy-headshot.png",
    name: "Nancy Viola",
    tagline: "L.M.S.W., C.C.M.\nHead of Social Work"
  }
];

export const pricingCards = [
  {
    title: "Light Support",
    price: "$50/month",
    description: "Best for families with small, straightfoward needs, like finding an eldercare attorney in Tampa, or a senior meal delivery program in Seattle."
  },
  {
    title: "More Support",
    price: "$350/month",
    description: "Best for families with slightly more complex needs, like finding a nursing home in Nashville, or an in-home care aide in Kansas.",
    mostPopular: true
  },
  {
    title: "Unlimited Support",
    price: "$750/month",
    description: "Best for families that need significant support accomplishing a larger goal, like moving a loved one across state lines while ensuring that they don't have a lapse in insurance coverage.",
  }
];

export const careCopilots = [
    {
      name: "Nancy Viola, L.M.S.W., C.C.M.",
      image: "https://carecopilot-static-assets.s3.amazonaws.com/nancy-headshot.png",
      // role: "Licensed Social worker, certified care manager, and former caregiver",
      description:
          <>
            Nancy is a licensed social worker, a certified care manager, and a former caregiver for her aunt. Nancy has over 30 years of experience working with families at prestigious healthcare institutions like {renderLink("New York Presbyterian Hospital", "https://www.nyp.org/")}. Nancy knows which adult day centers have the best activities, which eldercare attorneys have reasonable prices, and other information that helps families like yours spend less time finding resources, and more time enjoying your loved ones.
          </>
    },
    {
      name: "Alyse Dunn, B.B.A.",
      image: "https://carecopilot-static-assets.s3.amazonaws.com/founder-headshot.png",
      // role: "Former caregiver",
      description: "Alyse is the Founder and CEO of CareCopilot. Alyse is also a former caregiver. Alyse and her sister cared for their aging parents for a total of 8 years before both parents passed away. Their father died of complications from Multiple Sclerosis in 2014. Their mother died of Alzheimer's in 2020. In the course of Alyse's 8 years of caregiving, she learned about caregiver grants, support groups, and other practical resources that makes the day-to-day challenges of caregiving easier. Alyse is thrilled to be sharing this practical, implementable information with caregivers today."
    },
        {
      name: "Sasha Guriel, B.B.A.",
      image: "https://carecopilot-static-assets.s3.amazonaws.com/Sasha Guriel Headshot.jpeg",
      // role: "Former caregiver",
      description: "Sasha is a junior care copilot. Sasha works tirelessly behind the scenes making calls, completing forms, and taking other administrative duties off of the plates of busy caregivers. Sasha also helps the team continuously build out its robust, internal database of real-time resources for families, like cab companies that accept Medicaid, and nursing homes that were rated 5 stars by Medicare."
    }
];

export const homeBackedBy = [
  "Techstars",
  "Fearless Fund",
  "Google for Startups",
  "Visible Hands",
  "AWS"
];
