import React from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { signInUser } from "../actions";

import { trackClickMixpanel } from "utilities";

import Button from "./Button";

const SignUpButton = ({ callback, classNames, text }) => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = () => {
    trackClickMixpanel(text);
    window.sessionStorage.setItem("lastURL", window.location.pathname);
    loginWithRedirect({ screen_hint: 'signup' })
      .then(token => signInUser(token))
      .catch(err => console.log(err));
    if (callback) callback();
  };

  return (
    <Button onClick={handleSignUp} className={classNames}>{ text }</Button>
  );
};

export default connect(null, { signInUser })(SignUpButton);
