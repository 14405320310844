import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { signInUser } from "../actions";

import { truncate, useToken } from '../utilities';

import LoginButton from './Auth0LoginButton';
import LogoutButton from './Auth0LogoutButton';
import SignUpButton from './Auth0SignUpButton';

import CareCopilotLogoBlue from "assets/icons/CareCopilot-Logo-Blue.svg";

const Navbar = ({ currentUser, signInUser }) => {
  const token = useToken();
  const location = useLocation();
  const currentPage = location.pathname;
  const { isLoading, isAuthenticated } = useAuth0();
  const [isHamburgerOpen, setIsHamburgerOpen] = React.useState(false);
  const hamburgerRef = React.useRef();
  const [mobile, setMobile] = React.useState(window.innerWidth < 991.5);

  const updateScreenWidth = () => setMobile(window.innerWidth < 991.5);

  React.useEffect( () => {
    updateScreenWidth();
    window.addEventListener("resize", updateScreenWidth);
    return () => window.removeEventListener("resize", updateScreenWidth);
  }, []);

  // Render elements that vary depending on screen size or auth status
  const renderAuthNav = (classNames) => {
    // render the menu elements for a logged in user - manage posts, account, log out
    return (
      <>
        <Link className={classNames} to="/account" >Account</Link>
        <LogoutButton classNames={classNames} text="Sign Out">
          <i className="fa fa-sign-out fa-fw" aria-hidden="true"></i>
        </LogoutButton>
      </>
    )
  }

  const renderUnauthNav = (classNames) => {
    // render log in and sign up, which might be horizontal or in hamburger
    return (
      <>
        <LoginButton classNames={classNames + " btn-alternate"} />
        <SignUpButton classNames={classNames + " btn-main"} text="Sign Up" />
      </>
    )
  }

  const renderMainNavigation = (classNames) => {
    // render the navigation links that are always visible
    return (
      <>
        <Link className={classNames} to="/pricing">Pricing</Link>
        {/*<Link className={classNames} to="/community">Community</Link>*/}
        <Link className={classNames} to="/resources">Scripts, Videos & More</Link>
      </>
    )
  }

  const renderHamburgerIcon = () => {
    if (mobile) {
      return <div className={`fa fa-solid ${isHamburgerOpen ? "fa-x" : "fa-bars"} navbar-hamburger-menu`} onClick={toggleHamburger}></div>
    }

    if (currentUser && !mobile) {
      return (
        <div className={`navbar-user-container ${isHamburgerOpen ? "navbar-user-container-open" : ""}`} onClick={toggleHamburger}>
          <img className="navbar-avatar" src={currentUser?.profile_image} alt="User profile image" />
          <div className="text-md navbar-handle">{truncate(currentUser?.handle)}</div>
          <div className={`fa fa-solid navbar-caret 
          ${mobile ? (isHamburgerOpen ? "fa-xmark" : "fa-bars") : "fa-angle-down"}`}></div>
        </div>
      )
    }
  }

  const toggleHamburger = () => {
    if (!hamburgerRef.current) return;

    isHamburgerOpen
      ? hamburgerRef.current.classList.add("hidden")
      : hamburgerRef.current.classList.remove("hidden");
    setIsHamburgerOpen(!isHamburgerOpen);
  }

  const closeHamburger = e => {
    if (!e.target.closest(".navbar-user-container") && isHamburgerOpen) {
      toggleHamburger();
    }
  }

  React.useEffect(() => {
    window.addEventListener("click", closeHamburger);
    return () => window.removeEventListener("click", closeHamburger);
  });

  React.useEffect(() => {
    if (!currentUser && token) signInUser(token);
  }, [currentUser, signInUser, token]);

  // show orange border under current page
  const navbarLinksRef = React.useCallback(node => {
    if (node !== null) {
      const navbarLinks = node.querySelectorAll(".link-navbar");
      navbarLinks.forEach(link => {
        if (currentPage.includes(link.innerText.toLowerCase())) {
          link.classList.add("link-navbar-current");
        } else {
          link.classList.remove("link-navbar-current");
        }
      });
    }
  }, [currentPage]);

  if (isLoading || (!currentUser && isAuthenticated)) return (
    <div className="navbar-container">
      <div className="navbar-inner" />
    </div>
  );

  return (
    <>
      <div className="navbar-container">
        <div className="navbar-inner">
        <Link to="/">
          <img
            width={mobile ? "199" : "249"}
            height={mobile ? "34" : "43"}
            src={CareCopilotLogoBlue}
            alt="CareCopilot logo"
            title="logo"
          />
        </Link>

          <div className="navbar-links-container" ref={navbarLinksRef}>
            {!mobile && renderMainNavigation("text-md link-navbar")}
          </div>

          {/* Hamburger icon */}
          {renderHamburgerIcon()}

          <div className="navbar-hamburger-inner hidden" ref={hamburgerRef}>
            <Link to="/" className="btn-hamburger">Home</Link>
            {mobile && renderMainNavigation("btn-hamburger")}
            {currentUser && renderAuthNav("btn-hamburger")}
            {mobile && !currentUser && renderUnauthNav("btn-hamburger")}
          </div>


          <div className="navbar-unauthenticated">
            {!currentUser && !isAuthenticated && renderUnauthNav("btn-navbar")}
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.user
  };
};

export default connect(mapStateToProps, { signInUser })(Navbar);
