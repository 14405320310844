import { combineReducers } from 'redux';

import blogReducer from './blogReducer';
import categoryReducer from './categoryReducer';
import forumReducer from './forumReducer';
import providerReducer from './providerReducer';
import userReducer from './userReducer';

export default combineReducers({
  blog: blogReducer,
  categories: categoryReducer,
  forum: forumReducer,
  providers: providerReducer,
  user: userReducer
});
