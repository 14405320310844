import {
    FETCH_BLOGS_ERROR,
    FETCH_BLOGS_SUCCESS,
  } from '../actions/types';

import blogposts from "../blogposts/blogposts.json";
  
  const INITIAL_STATE = {
    selected: blogposts[0],
    all: blogposts,
    error: null
  };
  
  const blogReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_BLOGS_SUCCESS:
        return { ...state, all: action.payload, error: null };
      case FETCH_BLOGS_ERROR:
        return { ...state, all: [], error: action.payload };
      default:
        return state;
    }
  };
  
  export default blogReducer;
