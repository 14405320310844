import React from 'react';
import { Link } from 'react-router-dom';

import CareCopilotLogoWhite from "assets/icons/CareCopilot-Logo-White.svg";

const Footer = () => {
  const footerExternalLinks = [
    { facebook: "https://www.facebook.com/Carecopilot-101183095733582" },
    { instagram: "https://www.instagram.com/carecopilot.co/" },
    { tiktok: "https://www.tiktok.com/@carecopilot.co" },
    { twitter: "https://twitter.com/CareCopilotCo" },
    { linkedin: "https://www.linkedin.com/company/carecopilot/" },
    { medium: "https://carecopilot.medium.com/" }
  ];

  const footerNavLinks = {
    "Pricing": "/pricing",
    // "Community": "/community",
    "Scripts, Videos & More": "/resources",
    "Contact": "/contact"
  };

  const renderLink = (name, href) => {
    return (
      <a href={href} target="_blank" rel="noreferrer" key={name}>
        <i className={`fa fa-brands fa-${name} footer-icon`} />
      </a>
    )
  }

  return (
    <footer className="footer-container">
      <div className="footer-logo-container">
        <Link to="/">
          <img width="247" height="43" loading="lazy" src={CareCopilotLogoWhite} className="footer-logo" alt="CareCopilot logo" title="logo" />
        </Link>
      </div>
      <div className="text-md footer-nav-container">
        {Object.keys(footerNavLinks).map(link => {
          return (
            <Link className="footer-nav-link" to={footerNavLinks[link]} key={link}>
              {link}
            </Link>
          )
        })}
      </div>
      <div className="footer-icons-container">
        {footerExternalLinks.map(link => {
          return Object.entries(link).map(([key, value]) => {
            return renderLink(key, value);
          });
        })}
      </div>
    </footer>
  )
}

export default Footer;
