import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import ScrollToTop from "pages/ScrollToTop";

const Layout = () => {
  return (
    <ScrollToTop>
      <div className="page-container">
        <div className="fixed-header">
          <Navbar />
        </div>
        <div className="content-container">
          <Outlet />
        </div>
        <Footer />
      </div>
    </ScrollToTop>
  );
};


export default Layout;
