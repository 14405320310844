import React, { Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import mixpanel from 'mixpanel-browser';
import { lazyWithRetry, renderLoader, trackPageMixpanel } from 'utilities';

// layout
import Layout from 'Layout';

// pages
const Account =  lazyWithRetry(() => import('pages/Account'));
const BlogLanding =  lazyWithRetry(() => import('pages/Blog'));
const BlogPost =  lazyWithRetry(() => import('pages/Blog/BlogPost'));
const CareCopilots =  lazyWithRetry(() => import('pages/CareCopilots'));
const Contact =  lazyWithRetry(() => import('pages/Contact'));
const ConversationScripts = lazyWithRetry(() => import('pages/Resources/ConversationScripts'));
const Error404 = lazyWithRetry(() => import('pages/Error404'));
const Forum =  lazyWithRetry(() => import('pages/Forum'));
const ForumSinglePost =  lazyWithRetry(() => import('pages/Forum/ForumSinglePost'));
const Home =  lazyWithRetry(() => import('pages/Home'));
const InstructionalVideos = lazyWithRetry(() => import('pages/Resources/InstructionalVideos'));
const Onboarding =  lazyWithRetry(() => import('pages/Onboarding'));
const PostLogin =  lazyWithRetry(() => import('pages/PostLogin'));
const Faq =  lazyWithRetry(() => import('pages/Faq'));
const PostLogout =  lazyWithRetry(() => import('pages/PostLogout'));
const Pricing =  lazyWithRetry(() => import('pages/Pricing'));
const ResourcesLanding = lazyWithRetry(() => import('pages/Resources'));
const Services =  lazyWithRetry(() => import('pages/Services'));
const Unsubscribe = lazyWithRetry(() => import('pages/Unsubscribe'));

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { track_pageview: true });

const Router = () => {
  const location = useLocation();
  const currentPage = location.pathname;

  const useLocationEffect = (callback) => {
    const location = useLocation();

    React.useEffect(() => {
      callback(location);
    }, [location, callback]);
  }

  // Track page navigations in mixpanel
  useLocationEffect(trackPageMixpanel);

  React.useEffect( () => {
    // console.log("current page", currentPage);
    // Can send events here to google tags/analytics
    // Can also send events when a user clicks on the signup button
    // On click event on homepage?
  }, [currentPage]);

  React.useEffect(() => {
    // Track initial page
    trackPageMixpanel();
  }, []);

  return (
    <Suspense fallback={renderLoader()}>
      <Routes>
        <Route element={<Layout />}>

          <Route
          path="/error-404"
          element={<Error404 />}
          />

          <Route
          exact
          path="/"
          element={<Home />}
          />

          <Route
          exact
          path="/account"
          element={<Account />}
          />

          <Route
          exact
          path="/community"
          element={<Forum />}
          />

          <Route
          exact
          path="/community/:id"
          element={<ForumSinglePost />}
          />

          <Route
          path="/contact"
          element={<Contact />}
          />

          <Route
          exact
          path="/blog"
          element={<BlogLanding />}
          />

          <Route
          exact
          path="/blog/:url"
          element={<BlogPost />}
          />

          <Route
          exact
          path="/our-carecopilots"
          element={<CareCopilots />}
          />

          <Route
          exact
          path="/resources"
          element={<ResourcesLanding />}
          />

          <Route
          exact
          path="/faq"
          element={<Faq />}
          />

          <Route
          exact
          path="/scripts/:id"
          element={<ConversationScripts />}
          />

          <Route
          exact
          path="/videos/:id"
          element={<InstructionalVideos />}
          />

          <Route
          path="/services"
          element={<Services />}
          />

          <Route
          path="/onboarding"
          element={<Onboarding />}
          />

          <Route
          path="/post-login"
          element={<PostLogin />}
          />

          <Route
          path="/post-logout"
          element={<PostLogout />}
          />

          <Route
          path="/pricing"
          element={<Pricing />}
          />

          <Route
          path="/unsubscribe/:authID/:token"
          element={<Unsubscribe />}
          />

          <Route path="*" element={<Navigate to="/error-404" replace />} />
        </Route>
      </Routes>
    </Suspense>

  );
};

export default Router;
