import React from "react";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

// redux
import { Provider } from "react-redux";
import store from "store";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/main.scss";

// app
import App from "App";

// auth
import { Auth0Provider } from '@auth0/auth0-react';

ReactDOM.render(
  <HelmetProvider>
    <Helmet>
      <title>CareCopilot</title>
      <link rel="canonical" href="https://carecopilot.co/" />
    </Helmet>
    <Provider store={store}>
      <Auth0Provider
        // Envars must begin with REACT_APP_ to be available here or in any component
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin + "/post-login"}
        audience="https://carecopilotbeta.co/api/"
        scope="openid email profile"
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
    </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);
