import {
  CREATE_FORUM_COMMENT,
  CREATE_FORUM_COMMENT_ERROR,
  CREATE_FORUM_COMMENT_SUCCESS,
  CREATE_FORUM_POST,
  CREATE_FORUM_POST_ERROR,
  CREATE_FORUM_POST_SUCCESS,
  DELETE_FORUM_COMMENT,
  DELETE_FORUM_COMMENT_ERROR,
  DELETE_FORUM_COMMENT_SUCCESS,
  DELETE_FORUM_POST,
  DELETE_FORUM_POST_ERROR,
  DELETE_FORUM_POST_SUCCESS,
  FETCH_FORUM_TAGS,
  FETCH_FORUM_TAGS_ERROR,
  FETCH_FORUM_TAGS_SUCCESS,
  FETCH_FORUM_POST,
  FETCH_FORUM_POST_ERROR,
  FETCH_FORUM_POST_SUCCESS,
  FETCH_FORUM_POSTS,
  FETCH_FORUM_POSTS_ERROR,
  FETCH_FORUM_POSTS_SUCCESS,
  TOGGLE_FORUM_LIKE,
  TOGGLE_FORUM_LIKE_ERROR,
  TOGGLE_FORUM_LIKE_SUCCESS,
  TOGGLE_MANUAL_REPORT,
  TOGGLE_MANUAL_REPORT_ERROR,
  TOGGLE_MANUAL_REPORT_SUCCESS,
  UPDATE_FORUM_COMMENT,
  UPDATE_FORUM_COMMENT_ERROR,
  UPDATE_FORUM_COMMENT_SUCCESS,
  UPDATE_FORUM_POST,
  UPDATE_FORUM_POST_ERROR,
  UPDATE_FORUM_POST_SUCCESS,
  UPDATE_USER_INFO_SUCCESS
} from '../actions/types';
  
const INITIAL_STATE = {
  all: [],
  tags: [],
  posts: [],
  error: null,
  loading: null
};

// each error case returns { ...action.payload } instead of just action.payload in order to utilize the full error message object 
// and not a shortened version with just the name of the error
const forumReducer = (state = INITIAL_STATE, action) => {
  let posts;
  switch (action.type) {
    case CREATE_FORUM_POST:
      return { ...state, loading: true };
    case CREATE_FORUM_POST_ERROR:
      return { ...state, error: { ...action.payload }, loading: false };
    case CREATE_FORUM_POST_SUCCESS:
      return { ...state, posts: [action.payload, ...state.posts], error: null, loading: false };
    case DELETE_FORUM_POST:
      return { ...state, loading: true };
    case DELETE_FORUM_POST_ERROR:
      return { ...state, error: { ...action.payload }, loading: false }
    case DELETE_FORUM_POST_SUCCESS:
      posts = state.posts.filter(post => post.id !== action.payload);
      return { ...state, posts, error: null, loading: false };
    case FETCH_FORUM_TAGS:
      return { ...state, loading: true };
    case FETCH_FORUM_TAGS_ERROR:
      return { ...state, error: { ...action.payload }, loading: false };
    case FETCH_FORUM_TAGS_SUCCESS:
      return { ...state, tags: action.payload, error: null, loading: false };
    case FETCH_FORUM_POST:
      return { ...state, loading: true };
    case FETCH_FORUM_POST_ERROR:
      return { ...state, error: { ...action.payload }, loading: false };
    case FETCH_FORUM_POST_SUCCESS:
      return { ...state, posts: [action.payload], error: null, loading: false };
    case FETCH_FORUM_POSTS:
      return { ...state, loading: true };
    case FETCH_FORUM_POSTS_ERROR:
      return { ...state, error: { ...action.payload }, loading: false };
    case FETCH_FORUM_POSTS_SUCCESS:
      if (action.payload.previous) {
        // if the user makes a new post and then loads a new page, pagination will be offset by 1
        // ensure no duplicate posts are rendered
        posts = [...state.posts, ...action.payload.results]
          .filter((posts, i, arr) => {
            return i === arr.findIndex(post => (
              post.id === posts.id
            ))
          });
      } else posts = [...action.payload.results];
      return { ...state, all: action.payload, posts: posts, error: null, loading: false };
    case CREATE_FORUM_COMMENT:
      return { ...state, loading: true };
    case CREATE_FORUM_COMMENT_ERROR:
      return { ...state, error: { ...action.payload }, loading: false };
    case CREATE_FORUM_COMMENT_SUCCESS:
      posts = state.posts.map(post => {
        if (post.id === action.payload.post) {
          post.comments = [...post.comments, action.payload]
        }
        return post;
      });
      return { ...state, posts, error: null, loading: false };
    case DELETE_FORUM_COMMENT:
      return { ...state, loading: true };
    case DELETE_FORUM_COMMENT_ERROR:
      return { ...state, error: { ...action.payload }, loading: false };
    case DELETE_FORUM_COMMENT_SUCCESS:
      posts = state.posts.map(post => {
        post.comments = post.comments.filter(comment => comment.id !== action.payload);
        return post;
      })
      return { ...state, posts, error: null, loading: false };
    case TOGGLE_FORUM_LIKE:
      return { ...state, loading: true };
    case TOGGLE_FORUM_LIKE_SUCCESS:
      posts = state.posts.map(post => {
        if (post.id === action.payload.id) {
          return action.payload;
        }
        return post;
      });
      return { ...state, posts, error: null, loading: false };
    case TOGGLE_FORUM_LIKE_ERROR:
      return { ...state, error: { ...action.payload }, loading: false };
    case TOGGLE_MANUAL_REPORT:
      return { ...state, loading: true };
    case TOGGLE_MANUAL_REPORT_ERROR:
      return { ...state, error: { ...action.payload }, loading: false };
    case TOGGLE_MANUAL_REPORT_SUCCESS:
      return { ...state, error: null, loading: false };
    case UPDATE_FORUM_COMMENT:
      return { ...state, loading: true };
    case UPDATE_FORUM_COMMENT_ERROR:
      return { ...state, error: { ...action.payload }, loading: false };
    case UPDATE_FORUM_COMMENT_SUCCESS:
      posts = state.posts.map(post => {
        if (post.id === action.payload.post) {
          post.comments = post.comments.map(comment => {
            if (comment.id === action.payload.id) {
              return action.payload;
            }
            return comment;
          });
        }
        return post;
      });
      return { ...state, posts, error: null, loading: false };
    case UPDATE_FORUM_POST:
      return { ...state, loading: true };
    case UPDATE_FORUM_POST_ERROR:
      return { ...state, error: { ...action.payload }, loading: false };
    case UPDATE_FORUM_POST_SUCCESS:
      posts = state.posts.map(post => {
        if (post.id === action.payload.id) {
          return action.payload;
        }
        return post;
      });
      return { ...state, posts, error: null, loading: false }
    default:
      return state;
    case UPDATE_USER_INFO_SUCCESS:
      posts = state.posts.map(post => {
        if (action.payload.authID === post.owner_authID) {
          post.owner = action.payload.handle;
        }
        if (post.comments) {
          post.comments.forEach(comment => {
            if (comment.owner_authID === action.payload.authID) {
              comment.owner = action.payload.handle;
            }
          });
        }
        return post;
      })
      return state;
  }
};

export default forumReducer;
