import React from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { signInUser } from "../actions";

import Button from "./Button";

const LoginButton = ({ callback, classNames, text }) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    window.sessionStorage.setItem("lastURL", window.location.pathname);
    loginWithRedirect()
      .then(token => signInUser(token))
      .catch(err => console.log(err));
    if (callback) callback();
  };

  return <Button className={classNames} onClick={handleLogin}>{text || "Login"}</Button>
};

export default connect(null, { signInUser })(LoginButton);
