import { 
  BULK_CREATE_CARE_RECIPIENT_EXPENSE,
  BULK_CREATE_CARE_RECIPIENT_EXPENSE_ERROR,
  CREATE_CARE_RECIPIENT,
  CREATE_CARE_RECIPIENT_ERROR,
  CREATE_CARE_RECIPIENT_SUCCESS,
  CREATE_SURVEY_ANSWER,
  CREATE_SURVEY_ANSWER_ERROR,
  CREATE_USER,
  CREATE_USER_ERROR,
  CHECK_UNIQUE_USER_ATTRIBUTE,
  CHECK_UNIQUE_USER_ATTRIBUTE_ERROR,
  CHECK_UNIQUE_USER_ATTRIBUTE_SUCCESS,
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS, 
  SIGN_OUT,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  FETCH_USER_GROUPS,
  FETCH_USER_GROUPS_SUCCESS,
  FETCH_USER_GROUPS_ERROR,
  TOGGLE_SAVED_POST,
  TOGGLE_SAVED_POST_ERROR,
  TOGGLE_SAVED_POST_SUCCESS,
  UPDATE_CARE_RECIPIENT,
  UPDATE_CARE_RECIPIENT_ERROR,
  UPDATE_CARE_RECIPIENT_SUCCESS,
  UPDATE_NOTIFICATION_PREFERENCE,
  UPDATE_NOTIFICATION_PREFERENCE_ERROR,
  UPDATE_NOTIFICATION_PREFERENCE_SUCCESS,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_ERROR
} from '../actions/types';

const INITIAL_STATE = {
  error: null,
  groupDescription: null,
  isAttributeInUse: {},
  isSignedIn: null,
  loading: null,
  user: null
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BULK_CREATE_CARE_RECIPIENT_EXPENSE:
      return { ...state, loading: true };
    case BULK_CREATE_CARE_RECIPIENT_EXPENSE_ERROR:
      return { ...state, error: action.payload, loading: false };
    case CHECK_UNIQUE_USER_ATTRIBUTE:
      return { ...state, loading: true };
    case CHECK_UNIQUE_USER_ATTRIBUTE_ERROR:
      return { ...state, error: action.payload, loading: false };
    case CHECK_UNIQUE_USER_ATTRIBUTE_SUCCESS:
      // As only one attribute is checked at a time, checkedAttributes also stores the values of any previously checked attributes
      const checkedAttributes = { ...state.isAttributeInUse, ...action.payload}
      return { ...state, isAttributeInUse: checkedAttributes, loading: false };
    case CREATE_CARE_RECIPIENT:
      return { ...state, loading: true };
    case CREATE_CARE_RECIPIENT_ERROR:
      return { ...state, error: action.payload, loading: false };
    case CREATE_CARE_RECIPIENT_SUCCESS:
      let newUserData = {};
      const care_recipients = [...state.user.care_recipients, action.payload];
      if (state.user) newUserData = { ...state.user, care_recipients };
      return { ...state, user: newUserData, loading: false };
    case CREATE_SURVEY_ANSWER:
      return { ...state, loading: true };
    case CREATE_SURVEY_ANSWER_ERROR:
      return { ...state, error: action.payload, loading: false };
    case CREATE_USER:
      return { ...state, loading: true };
    case CREATE_USER_ERROR:
      return { ...state, error: action.payload, loading: false };
    case SIGN_IN:
      return { ...state, loading: true };
    case SIGN_IN_ERROR:
      return { ...state, isSignedIn: false, user: null, error: action.payload, loading: false };
    case SIGN_IN_SUCCESS:
      return { ...state, isSignedIn: true, user: action.payload, error: null, loading: false };
    case SIGN_OUT:
      return { ...state, isSignedIn: false, user: null, loading: false };
    case FETCH_USER:
      return { ...state, loading: true };
    case FETCH_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false };
    case FETCH_USER_ERROR:
      return { ...state, error: action.payload, loading: false };
    case FETCH_USER_GROUPS:
      return { ...state, loading: true };
    case FETCH_USER_GROUPS_SUCCESS:
      return { ...state, groupDescription: action.payload, loading: false };
    case FETCH_USER_GROUPS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case TOGGLE_SAVED_POST:
      return { ...state, loading: true };
    case TOGGLE_SAVED_POST_ERROR:
      return { ...state, error: action.payload, loading: false };
    case TOGGLE_SAVED_POST_SUCCESS:
      return { ...state, error: null, user: action.payload, loading: false };
    case UPDATE_CARE_RECIPIENT:
      return {...state, loading: true};
    case UPDATE_CARE_RECIPIENT_ERROR:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_CARE_RECIPIENT_SUCCESS:
      let userUpdatedData = {};
      if (state.user) {
        const careRecipients = state.user.care_recipients;
        const updatedCareRecipients = careRecipients.map(careRecipient => {
          if (careRecipient.id === action.payload.id) {
            return action.payload;
          }
          return careRecipient;
        })
        userUpdatedData = { ...state.user, care_recipients: updatedCareRecipients };
      }
      return { ...state, user: userUpdatedData, loading: false };
    case UPDATE_NOTIFICATION_PREFERENCE:
      return { ...state, loading: true };
    case UPDATE_NOTIFICATION_PREFERENCE_ERROR:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_NOTIFICATION_PREFERENCE_SUCCESS:
      return { ...state, user: action.payload, loading: false };
    case UPDATE_USER_INFO:
      return { ...state, loading: true };
    case UPDATE_USER_INFO_SUCCESS:
      return { ...state, user: action.payload, loading: false };
    case UPDATE_USER_INFO_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default userReducer;
