import React from 'react';
import { 
  createBrowserRouter,
  RouterProvider,
 } from "react-router-dom";

import Router from 'router';

const router = createBrowserRouter([
  { path: "*", Component: Router },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
