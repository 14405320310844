import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_ERROR,
  FETCH_CATEGORIES_SUCCESS,
  SET_CATEGORY,
  SET_SUBCATEGORIES
} from '../actions/types';

const INITIAL_STATE = {
  selected: { main: null, sub: null },
  all: [],
  error: null,
  loading: null
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return { ...state, loading: true };
    case FETCH_CATEGORIES_SUCCESS:
      return { ...state, all: action.payload, error: null, loading: false };
    case FETCH_CATEGORIES_ERROR:
      return { ...state, all: [], error: action.payload, loading: false };
    case SET_CATEGORY:
      const main = state.all.find(category => action.payload === category.id) || null;
      const newSelectedMain = { ...state.selected, main };
      return { ...state, selected: newSelectedMain, loading: false };
    case SET_SUBCATEGORIES:
      const newSelectedSub = { ...state.selected, sub: action.payload };
      return { ...state, selected: newSelectedSub, loading: false };
    default:
      return state;
  }
};

export default categoryReducer;
