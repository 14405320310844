import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Button from "./Button";

const LogoutButton = ({ children, classNames, text }) => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    window.sessionStorage.setItem("lastURL", window.location.pathname);
    const redirectUri = window.location.origin + "/post-logout" || "/";
    return logout({returnTo:redirectUri});
  }

  return (
    <Button className={classNames} onClick={handleLogout}>
      {children}
      {text}
    </Button>
  );
};

export default LogoutButton;
