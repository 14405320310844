import { devServer } from '../utilities';
import * as types from './types';

export const checkUniqueUserAttribute = (attribute, updatedAttribute, token) => async dispatch => {
  dispatch({ type: types.CHECK_UNIQUE_USER_ATTRIBUTE });
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  const response = await devServer.get(`/api/user/attributes?${attribute}=${updatedAttribute}`).catch((error) => {return {error}});
  if(response.data) {
    dispatch({ type: types.CHECK_UNIQUE_USER_ATTRIBUTE_SUCCESS, payload: response.data });
  } else {
    dispatch({ type: types.CHECK_UNIQUE_USER_ATTRIBUTE_ERROR, payload: "Unknown Server Error" });
  }
}

export const createUser = (userData) => async dispatch => {
  dispatch({ type: types.CREATE_USER });
  const response = await devServer.post(`/api/user/`, userData).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.SIGN_IN_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.CREATE_USER_ERROR, payload: response.error })
  } else {
    dispatch({ type: types.CREATE_USER_ERROR, payload: "Unknown Server Error" });
  }
}

export const fetchUser = (authID, token) => async dispatch => { 
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // Get and update the user in the state
  dispatch({ type: types.FETCH_USER });
  const response = await devServer.get(`/api/user/${authID}`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.FETCH_USER_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.FETCH_USER_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.FETCH_USER_ERROR, payload: "Unknown Server Error" });
  }
}

export const fetchServiceCategories = (city) => async dispatch => {
  // Expects city to be lowercase, separated by dashes: new-york-city
  dispatch({ type: types.FETCH_CATEGORIES });
  const response = await devServer.get(`/api/${city}/`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.FETCH_CATEGORIES_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.FETCH_CATEGORIES_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.FETCH_CATEGORIES_ERROR, payload: "Unknown Server Error" });
  }
};

export const fetchServiceProviders = (city, providerType) => async (dispatch, getState) => {
  // Expects city and provider type to be lowercase, separated by dashes: new-york-city
  dispatch({ type: types.FETCH_SERVICE_PROVIDERS });
  const response = await devServer.get(`/api/${city}/${providerType}/`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.FETCH_SERVICE_PROVIDERS_SUCCESS, payload: response.data.service_providers });
    let subcategories = response.data.subcategories.length ? response.data.subcategories : null;
    dispatch({ type: types.SET_SUBCATEGORIES, payload: subcategories });
  } else if (response.error) {
    dispatch({ type: types.FETCH_SERVICE_PROVIDERS_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.FETCH_SERVICE_PROVIDERS_ERROR, payload: "Unknown Server Error" });
  }  
};

// To be updated when there is an endpoint for fetching one service provider
export const fetchServiceProvider = (providerType, providerURL, providerCity) => async dispatch => {
  dispatch({ type: types.FETCH_SERVICE_PROVIDER });
  const response = await devServer.get(`/api/${providerCity}/${providerType}/${providerURL}`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.FETCH_SERVICE_PROVIDER_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.FETCH_SERVICE_PROVIDER_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.FETCH_SERVICE_PROVIDER_ERROR, payload: "Unknown Server Error" });
  }  
};

// Fetch list of providers associated with a specific user
export const fetchServiceProvidersByUser = (authID, token=null) => async dispatch => {
  dispatch({ type: types.FETCH_SERVICE_PROVIDERS });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // Get services by user
  const response = await devServer.get(`/api/user/${authID}/services/`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.FETCH_SERVICE_PROVIDERS_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.FETCH_SERVICE_PROVIDERS_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.FETCH_SERVICE_PROVIDERS_ERROR, payload: "Unknown Server Error" });
  }
};

// Fetch group description associated with a specific user's groups
export const fetchUserGroups = (authID, token) => async dispatch => {
  dispatch({ type: types.FETCH_USER_GROUPS });
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  const response = await devServer.get(`/api/user/${authID}/groups/`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.FETCH_USER_GROUPS_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.FETCH_USER_GROUPS_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.FETCH_USER_GROUPS_ERROR, payload: "Unknown Server Error" });
  }
}

// Create a new forum comment
export const createForumComment = (comment, token) => async dispatch => {
  dispatch({ type: types.CREATE_FORUM_COMMENT });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;

  const response = await devServer.post(`/api/community/comments/`, comment).catch((error) => {return {error}});

  if (response.data) {
    dispatch({ type: types.CREATE_FORUM_COMMENT_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.CREATE_FORUM_COMMENT_ERROR, payload: response.error })
  } else {
    dispatch({ type: types.CREATE_FORUM_COMMENT_ERROR, payload: "Unknown Server Error" });
  }
}

// Update an existing forum comment
export const updateForumComment = (comment, commentID, token) => async dispatch => {
  dispatch({ type: types.UPDATE_FORUM_COMMENT });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  const response = await devServer.patch(`/api/community/comments/${commentID}/`, comment).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.UPDATE_FORUM_COMMENT_SUCCESS, payload: response.data });
  } else {
    dispatch({ type: types.UPDATE_FORUM_COMMENT_ERROR, payload: response.error || "Unknown Server Error"});
  }
}

// Delete a forum comment
export const deleteForumComment = (commentID, token) => async dispatch => {
  dispatch({ type: types.DELETE_FORUM_COMMENT });
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  const response = await devServer.delete(`/api/community/comments/${commentID}`).catch((error) => {return {error}});
  if (response.status === 204) {
    dispatch({ type: types.DELETE_FORUM_COMMENT_SUCCESS, payload: commentID });
  } else {
    dispatch({ type: types.DELETE_FORUM_COMMENT_ERROR, payload: response.error || "Unknown Server Error" })
  }
}

// Create a new forum post
export const createForumPost = (post, token) => async dispatch => {
  dispatch({ type: types.CREATE_FORUM_POST });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;

  const response = await devServer.post(`/api/community/posts/`, post).catch((error) => {return {error}});

  if (response.data) {
    dispatch({ type: types.CREATE_FORUM_POST_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.CREATE_FORUM_POST_ERROR, payload: response.error })
  } else {
    dispatch({ type: types.CREATE_FORUM_POST_ERROR, payload: "Unknown Server Error" });
  }
}

// Update an existing forum post
export const updateForumPost = (post, postID, token) => async dispatch => {
  dispatch({ type: types.UPDATE_FORUM_POST });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  const response = await devServer.patch(`/api/community/posts/${postID}/`, post).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.UPDATE_FORUM_POST_SUCCESS, payload: response.data });
  } else {
    dispatch({ type: types.UPDATE_FORUM_POST_ERROR, payload: response.error || "Unknown Server Error"});
  }
}

// Delete a forum post
export const deleteForumPost = (postID, token) => async dispatch => {
  dispatch({ type: types.DELETE_FORUM_POST });
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  const response = await devServer.delete(`/api/community/posts/${postID}`).catch((error) => {return {error}});
  if (response.status === 204) {
    dispatch({ type: types.DELETE_FORUM_POST_SUCCESS, payload: postID });
  } else {
    dispatch({ type: types.DELETE_FORUM_POST_ERROR, payload: response.error || "Unknown Server Error" })
  }
}

// Fetch all forum post categories from the database
export const fetchForumTags = () => async dispatch => {
  dispatch({ type: types.FETCH_FORUM_TAGS });
  const response = await devServer.get(`/api/community/tags/`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.FETCH_FORUM_TAGS_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.FETCH_FORUM_TAGS_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.FETCH_FORUM_TAGS_ERROR, payload: "Unknown Server Error" });
  }
}

// Fetch a single forum post by ID
export const fetchForumPost = (id) => async dispatch => {
  dispatch({ type: types.FETCH_FORUM_POST });
  const response = await devServer.get(`/api/community/posts/${id}/`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.FETCH_FORUM_POST_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.FETCH_FORUM_POST_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.FETCH_FORUM_POST_ERROR, payload: "Unknown Server Error" });
  }
}

// Fetch a list of forum posts
export const fetchForumPosts = (page, tags, search) => async dispatch => {
  dispatch({ type: types.FETCH_FORUM_POSTS });
  let URL = search ? `/api/community/posts?page=${page}${tags}&search=${search}` : `/api/community/posts?page=${page}${tags}`;
  const response = await devServer.get(URL).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.FETCH_FORUM_POSTS_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.FETCH_FORUM_POSTS_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.FETCH_FORUM_POSTS_ERROR, payload: "Unknown Server Error" });
  }
}

// Fetch forum posts created by a specific user
export const fetchPostsByUser = (user, page) => async dispatch => {
  dispatch({ type: types.FETCH_FORUM_POSTS });
  const response = await devServer.get(`/api/community/posts?user=${user}&page=${page}`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.FETCH_FORUM_POSTS_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.FETCH_FORUM_POSTS_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.FETCH_FORUM_POSTS_ERROR, payload: "Unknown Server Error" });
  }
}

// Toggle whether user liked a post or comment
export const toggleForumLike = (model, id, token) => async dispatch => {
  dispatch({ type: types.TOGGLE_FORUM_LIKE });
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  const response = await devServer.post(`/api/community/like/${model}/${id}/`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.TOGGLE_FORUM_LIKE_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.TOGGLE_FORUM_LIKE_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.TOGGLE_FORUM_LIKE_ERROR, payload: "Unknown Server Error" });
  }
}

// Allow users to manually report a post or comment and to retract a report
export const toggleManualForumReport = (model, id, token) => async dispatch => {
  dispatch({ type: types.TOGGLE_MANUAL_REPORT });
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  const response = await devServer.post(`/api/community/report/${model}/${id}/`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.TOGGLE_MANUAL_REPORT_SUCCESS, payload: response.data });
  } else {
    dispatch({ type: types.TOGGLE_MANUAL_REPORT_ERROR, payload: response.error || "Unknown Server Error" });
  }
}

// Allow users to save or unsave a post
export const toggleSavedPost = (authID, postID, token) => async dispatch => {
  dispatch({ type: types.TOGGLE_SAVED_POST });
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  const response = await devServer.post(`/api/user/${authID}/${postID}/`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.TOGGLE_SAVED_POST_SUCCESS, payload: response.data });
  } else {
    dispatch({ type: types.TOGGLE_SAVED_POST_ERROR, payload: response.error || "Unknown Server Error" });
  }
}

export const updateServiceProvider = (data, providerURL, token) => async dispatch => {
  dispatch({ type: types.UPDATE_SERVICE_PROVIDER });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // Send patch request
  const response = await devServer.patch(`/api/account/update/service-provider/${providerURL}/`, data).catch((error) => {return {error}});
  if(response.data) {
    dispatch({ type: types.UPDATE_SERVICE_PROVIDER_SUCCESS, payload: response.data });
  } else if(response.error) {
    dispatch({ type: types.UPDATE_SERVICE_PROVIDER_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.UPDATE_SERVICE_PROVIDER_ERROR, payload: "Unknown Server Error" });
  }
}

export const updateUserInfo = (data, authID, token) => async dispatch => {
  dispatch({ type: types.UPDATE_USER_INFO });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // Send patch request
  const response = await devServer.patch(`/api/user/${authID}/`, data).catch((error) => {return {error}});
  if(response.data) {
    dispatch({ type: types.UPDATE_USER_INFO_SUCCESS, payload: response.data });
  } else if(response.error) {
    dispatch({ type: types.UPDATE_USER_INFO_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.UPDATE_USER_INFO_ERROR, payload: "Unknown Server Error" });
  }
}

// Sets selected service category on state
export const setCategory = category => {
  return { type: types.SET_CATEGORY, payload: category };
};

// Sets selected service provider on state
export const setServiceProvider = provider => {
  return { type: types.SET_SERVICE_PROVIDER, payload: provider };
};

// Sets the user in the redux state
export const signInUser = (token) => async dispatch => {
  dispatch({ type: types.SIGN_IN });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // Create user locally if user does not exist, sign in user in Django
  const response = await devServer.post(`/api/user/`).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.SIGN_IN_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.SIGN_IN_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.SIGN_IN_ERROR, payload: response.error });
  }
}

export const signOutUser = () => {
  return { type: types.SIGN_OUT }
}

export const createCareRecipient = (data, token) => async dispatch => {
  dispatch({ type: types.CREATE_CARE_RECIPIENT });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // Post care recipient data to database
  const response = await devServer.post(`/api/care-recipient/`, data).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.CREATE_CARE_RECIPIENT_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.CREATE_CARE_RECIPIENT_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.CREATE_CARE_RECIPIENT_ERROR, payload: response.error });
  }
}

export const updateCareRecipient = (careRecipient, data, token) => async dispatch => {
  const pk = careRecipient.id;
  dispatch({ type: types.UPDATE_CARE_RECIPIENT });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // Post care recipient data to database
  const response = await devServer.patch(`/api/care-recipient/${pk}/`, data).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.UPDATE_CARE_RECIPIENT_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.UPDATE_CARE_RECIPIENT_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.UPDATE_CARE_RECIPIENT_ERROR, payload: response.error });
  }
}

// Change to take one care recipient and a bunch of expense type/value pairs, then dispatch
// success after promise.all ?
export const createCareRecipientExpense = (value, expenseID, recipientID, token) => async dispatch => {
  dispatch({ type: types.CREATE_CARE_RECIPIENT_EXPENSE });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // Post the value (estimated cost) to database as a new care recipient expense
  // This will include refs to a care recipient and a care expense, so those IDs are required
  let data = {care_expense: expenseID, care_recipient: recipientID, estimated_cost: value};
  const response = await devServer.post(`/api/care-recipient-expenses/`, data).catch((error) => {return {error}});
  if (response.data) {
    dispatch({ type: types.CREATE_CARE_RECIPIENT_SUCCESS, payload: response.data });
  } else if (response.error) {
    dispatch({ type: types.CREATE_CARE_RECIPIENT_ERROR, payload: response.error });
  } else {
    dispatch({ type: types.CREATE_CARE_RECIPIENT_ERROR, payload: response.error });
  }
}

export const bulkCreateCareRecipientExpenses = (authID, expenses, recipientID, token) => async dispatch => {
  dispatch({ type: types.BULK_CREATE_CARE_RECIPIENT_EXPENSE });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // Post the value (estimated cost) to database as a new care recipient expense
  // This will include refs to a care recipient and a care expense, so those IDs are required
  let expensesToPost = [];
  expenses.forEach(({expenseID, value}) => {
    let data = {care_expense: expenseID, care_recipient: recipientID, estimated_cost: value};
    expensesToPost.push(devServer.post(`/api/care-recipient-expenses/`, data)
      .then(resp => resp)
      .catch((error) => {return {error}}));
  });
  Promise.all(expensesToPost)
  .then(response => {
    if (response) {
      dispatch({ type: types.BULK_CREATE_CARE_RECIPIENT_EXPENSE_SUCCESS, payload: response.map(r => r.data) });
    } else {
      dispatch({ type: types.BULK_CREATE_CARE_RECIPIENT_EXPENSE_ERROR, payload: "Server error creating new expenses" });
    }
  })
  .catch((error) => {
    console.log(error)
    return {error}});
}

export const updateNotificationPreference = (data, authID, token) => async dispatch => {
  dispatch({ type: types.UPDATE_NOTIFICATION_PREFERENCE });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // Update the user's notification preference
  const response = await devServer.patch(`/api/user/${authID}/notification-preference/`, data).catch((error) => {return {error}});
  if(response.data) {
    dispatch({ type: types.UPDATE_USER_INFO_SUCCESS, payload: response.data });
  } else {
    dispatch({ type: types.UPDATE_NOTIFICATION_PREFERENCE_ERROR, payload: response.error || "Unknown Server Error" });
  }
}

export const createSurveyAnswer = (data, authID, token) => async dispatch => {
  dispatch({ type: types.CREATE_SURVEY_ANSWER });
  // Add token to header
  devServer.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // Post will create or update a survey question answer
  const response = await devServer.post(`/api/survey-answers/`, data).catch((error) => {return {error}});
  if(response.data) {
    dispatch(fetchUser(authID, token));
  } else {
    dispatch({ type: types.CREATE_SURVEY_ANSWER_ERROR, payload: response.error || "Unknown Server Error" });
  }
}
