// Auth types for userReducer
export const BULK_CREATE_CARE_RECIPIENT_EXPENSE = 'BULK_CREATE_CARE_RECIPIENT_EXPENSE';
export const BULK_CREATE_CARE_RECIPIENT_EXPENSE_ERROR = 'BULK_CREATE_CARE_RECIPIENT_EXPENSE_ERROR';
export const BULK_CREATE_CARE_RECIPIENT_EXPENSE_SUCCESS = 'BULK_CREATE_CARE_RECIPIENT_EXPENSE_SUCCESS';
export const CHECK_UNIQUE_USER_ATTRIBUTE = 'CHECK_UNIQUE_USER_ATTRIBUTE';
export const CHECK_UNIQUE_USER_ATTRIBUTE_ERROR = 'CHECK_UNIQUE_USER_ATTRIBUTE_ERROR';
export const CHECK_UNIQUE_USER_ATTRIBUTE_SUCCESS = 'CHECK_UNIQUE_USER_ATTRIBUTE_SUCCESS';
export const CREATE_CARE_RECIPIENT = 'CREATE_CARE_RECIPIENT';
export const CREATE_CARE_RECIPIENT_ERROR = 'CREATE_CARE_RECIPIENT_ERROR';
export const CREATE_CARE_RECIPIENT_SUCCESS = 'CREATE_CARE_RECIPIENT_SUCCESS';
export const CREATE_CARE_RECIPIENT_EXPENSE = 'CREATE_CARE_RECIPIENT_EXPENSE';
export const CREATE_CARE_RECIPIENT_EXPENSE_ERROR = 'CREATE_CARE_RECIPIENT_EXPENSE_ERROR';
export const CREATE_CARE_RECIPIENT_EXPENSE_SUCCESS = 'CREATE_CARE_RECIPIENT_EXPENSE_SUCCESS';
export const CREATE_SURVEY_ANSWER = 'CREATE_SURVEY_ANSWER';
export const CREATE_SURVEY_ANSWER_ERROR = 'CREATE_SURVEY_ANSWER_ERROR';
export const CREATE_SURVEY_ANSWER_SUCCESS = 'CREATE_SURVEY_ANSWER_SUCCESS';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_OUT = 'SIGN_OUT';
export const TOGGLE_SAVED_POST = 'TOGGLE_SAVED_POST';
export const TOGGLE_SAVED_POST_ERROR = 'TOGGLE_SAVED_POST_ERROR';
export const TOGGLE_SAVED_POST_SUCCESS = 'TOGGLE_SAVED_POST_SUCCESS';
export const UPDATE_CARE_RECIPIENT = 'UPDATE_CARE_RECIPIENT';
export const UPDATE_CARE_RECIPIENT_ERROR = 'UPDATE_CARE_RECIPIENT_ERROR';
export const UPDATE_CARE_RECIPIENT_SUCCESS = 'UPDATE_CARE_RECIPIENT_SUCCESS';
export const UPDATE_CARE_RECIPIENT_EXPENSE = 'UPDATE_CARE_RECIPIENT_EXPENSE';
export const UPDATE_CARE_RECIPIENT_EXPENSE_ERROR = 'UPDATE_CARE_RECIPIENT_EXPENSE_ERROR';
export const UPDATE_CARE_RECIPIENT_EXPENSE_SUCCESS = 'UPDATE_CARE_RECIPIENT_EXPENSE_SUCCESS';
export const UPDATE_NOTIFICATION_PREFERENCE = 'UPDATE_NOTIFICATION_PREFERENCE';
export const UPDATE_NOTIFICATION_PREFERENCE_ERROR = 'UPDATE_NOTIFICATION_PREFERENCE_ERROR';
export const UPDATE_NOTIFICATION_PREFERENCE_SUCCESS = 'UPDATE_NOTIFICATION_PREFERENCE_SUCCESS';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_ERROR = 'UPDATE_USER_INFO_ERROR';

// Blog actions for blogReducer
export const FETCH_BLOGS_ERROR = 'FETCH_BLOGS_ERROR';
export const FETCH_BLOGS_SUCCESS = 'FETCH_BLOGS_SUCCESS';

// Forum post actions for forumReducer
export const CREATE_FORUM_COMMENT = 'CREATE_FORUM_COMMENT';
export const CREATE_FORUM_COMMENT_ERROR = 'CREATE_FORUM_COMMENT_ERROR';
export const CREATE_FORUM_COMMENT_SUCCESS = 'CREATE_FORUM_COMMENT_SUCCESS';
export const CREATE_FORUM_POST = 'CREATE_FORUM_POST';
export const CREATE_FORUM_POST_ERROR = 'CREATE_FORUM_POST_ERROR';
export const CREATE_FORUM_POST_SUCCESS = 'CREATE_FORUM_POST_SUCCESS';
export const DELETE_FORUM_COMMENT = 'DELETE_FORUM_COMMENT';
export const DELETE_FORUM_COMMENT_ERROR = 'DELETE_FORUM_COMMENT_ERROR';
export const DELETE_FORUM_COMMENT_SUCCESS = 'DELETE_FORUM_COMMENT_SUCCESS';
export const DELETE_FORUM_POST = 'DELETE_FORUM_POST';
export const DELETE_FORUM_POST_ERROR = 'DELETE_FORUM_POST_ERROR';
export const DELETE_FORUM_POST_SUCCESS = 'DELETE_FORUM_POST_SUCCESS';
export const FETCH_FORUM_TAGS = 'FETCH_FORUM_TAGS';
export const FETCH_FORUM_TAGS_SUCCESS = 'FETCH_FORUM_TAGS_SUCCESS';
export const FETCH_FORUM_TAGS_ERROR = 'FETCH_FORUM_TAGS_ERROR';
export const FETCH_FORUM_POST = 'FETCH_FORUM_POST';
export const FETCH_FORUM_POST_ERROR = 'FETCH_FORUM_POST_ERROR';
export const FETCH_FORUM_POST_SUCCESS = 'FETCH_FORUM_POST_SUCCESS';
export const FETCH_FORUM_POSTS = 'FETCH_FORUM_POSTS';
export const FETCH_FORUM_POSTS_ERROR = 'FETCH_FORUM_POSTS_ERROR';
export const FETCH_FORUM_POSTS_SUCCESS = 'FETCH_FORUM_POSTS_SUCCESS';
export const TOGGLE_FORUM_LIKE = 'TOGGLE_FORUM_LIKE';
export const TOGGLE_FORUM_LIKE_ERROR = 'TOGGLE_FORUM_LIKE_ERROR';
export const TOGGLE_FORUM_LIKE_SUCCESS = 'TOGGLE_FORUM_LIKE_SUCCESS';
export const TOGGLE_MANUAL_REPORT = 'TOGGLE_MANUAL_REPORT';
export const TOGGLE_MANUAL_REPORT_ERROR = 'TOGGLE_MANUAL_REPORT_ERROR';
export const TOGGLE_MANUAL_REPORT_SUCCESS = 'TOGGLE_MANUAL_REPORT_SUCCESS';
export const UPDATE_FORUM_COMMENT = 'UPDATE_FORUM_COMMENT';
export const UPDATE_FORUM_COMMENT_ERROR = 'UPDATE_FORUM_COMMENT_ERROR';
export const UPDATE_FORUM_COMMENT_SUCCESS = 'UPDATE_FORUM_COMMENT_SUCCESS';
export const UPDATE_FORUM_POST = 'UPDATE_FORUM_POST';
export const UPDATE_FORUM_POST_ERROR = 'UPDATE_FORUM_POST_ERROR';
export const UPDATE_FORUM_POST_SUCCESS = 'UPDATE_FORUM_POST_SUCCESS';
// something like UPDATE_QUERY to store the question answers

// Service types for providerReducer
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_SERVICE_PROVIDERS = 'FETCH_SERVICE_PROVIDERS';
export const FETCH_SERVICE_PROVIDERS_ERROR = 'FETCH_SERVICE_PROVIDERS_ERROR';
export const FETCH_SERVICE_PROVIDERS_SUCCESS = 'FETCH_SERVICE_PROVIDERS_SUCCESS';
export const FETCH_SERVICE_PROVIDER = 'FETCH_SERVICE_PROVIDER';
export const FETCH_SERVICE_PROVIDER_ERROR = 'FETCH_SERVICE_PROVIDER_ERROR';
export const FETCH_SERVICE_PROVIDER_SUCCESS = 'FETCH_SERVICE_PROVIDER_SUCCESS';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'
export const FETCH_USER_GROUPS = 'FETCH_USER_GROUPS';
export const FETCH_USER_GROUPS_SUCCESS = 'FETCH_USER_GROUPS_SUCCESS'
export const FETCH_USER_GROUPS_ERROR = 'FETCH_USER_GROUPS_ERROR'
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_SUBCATEGORIES = 'SET_SUBCATEGORIES';
export const SET_SERVICE_PROVIDER = 'SET_SERVICE_PROVIDER';
export const UPDATE_SERVICE_PROVIDER = 'UPDATE_SERVICE_PROVIDER';
export const UPDATE_SERVICE_PROVIDER_ERROR = 'UPDATE_SERVICE_PROVIDER_ERROR';
export const UPDATE_SERVICE_PROVIDER_SUCCESS = 'UPDATE_SERVICE_PROVIDER_SUCCESS';
