import {
  FETCH_SERVICE_PROVIDER,
  FETCH_SERVICE_PROVIDER_ERROR,
  FETCH_SERVICE_PROVIDER_SUCCESS,
  FETCH_SERVICE_PROVIDERS,
  FETCH_SERVICE_PROVIDERS_ERROR,
  FETCH_SERVICE_PROVIDERS_SUCCESS,
  SET_SERVICE_PROVIDER,
  UPDATE_SERVICE_PROVIDER,
  UPDATE_SERVICE_PROVIDER_ERROR,
  UPDATE_SERVICE_PROVIDER_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  selected: null,
  all: [], 
  error: null,
  loading: null
};

const providerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SERVICE_PROVIDER:
      return { ...state, loading: true };
    case FETCH_SERVICE_PROVIDER_SUCCESS:
      return { ...state, selected: action.payload, error: null, loading: false };
    case FETCH_SERVICE_PROVIDER_ERROR:
      return { ...state, selected: null, error: action.payload, loading: false };
    case FETCH_SERVICE_PROVIDERS:
      return { ...state, loading: true };
    case FETCH_SERVICE_PROVIDERS_SUCCESS:
      return { ...state, all: action.payload, error: null, loading: false };
    case FETCH_SERVICE_PROVIDERS_ERROR:
      return { ...state, all: [], error: action.payload, loading: false };
    case SET_SERVICE_PROVIDER:
      const selected = action.payload;
      return { ...state, selected, loading: false };
    case UPDATE_SERVICE_PROVIDER:
      return { ...state, loading: true };
    case UPDATE_SERVICE_PROVIDER_ERROR:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_SERVICE_PROVIDER_SUCCESS:
      let updated_providers = state.all.map(provider => {
        return provider.detailURL === action.payload.detailURL ? action.payload : provider;
      });
      return { ...state, all: updated_providers, selected: action.payload, error: null, loading: false };
    default:
      return state;
  }
};

export default providerReducer;
